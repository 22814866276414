// package: com.addrobots.grpc
// file: device_service_def.proto

var device_service_def_pb = require("./device_service_def_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var DeviceService = (function () {
  function DeviceService() {}
  DeviceService.serviceName = "com.addrobots.grpc.DeviceService";
  return DeviceService;
}());

DeviceService.CreateDevice = {
  methodName: "CreateDevice",
  service: DeviceService,
  requestStream: false,
  responseStream: false,
  requestType: device_service_def_pb.DeviceReq,
  responseType: device_service_def_pb.CreateDeviceResp
};

DeviceService.UpdateDevice = {
  methodName: "UpdateDevice",
  service: DeviceService,
  requestStream: false,
  responseStream: false,
  requestType: device_service_def_pb.DeviceReq,
  responseType: device_service_def_pb.UpdateDeviceResp
};

DeviceService.FindDevice = {
  methodName: "FindDevice",
  service: DeviceService,
  requestStream: false,
  responseStream: false,
  requestType: device_service_def_pb.DeviceSearch,
  responseType: device_service_def_pb.FindDeviceResp
};

DeviceService.FindDevices = {
  methodName: "FindDevices",
  service: DeviceService,
  requestStream: false,
  responseStream: false,
  requestType: device_service_def_pb.DeviceSearch,
  responseType: device_service_def_pb.FindDeviceListResp
};

DeviceService.DeleteDevice = {
  methodName: "DeleteDevice",
  service: DeviceService,
  requestStream: false,
  responseStream: false,
  requestType: device_service_def_pb.DeviceReq,
  responseType: device_service_def_pb.DeleteDeviceResp
};

exports.DeviceService = DeviceService;

function DeviceServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

DeviceServiceClient.prototype.createDevice = function createDevice(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceService.CreateDevice, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceServiceClient.prototype.updateDevice = function updateDevice(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceService.UpdateDevice, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceServiceClient.prototype.findDevice = function findDevice(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceService.FindDevice, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceServiceClient.prototype.findDevices = function findDevices(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceService.FindDevices, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceServiceClient.prototype.deleteDevice = function deleteDevice(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceService.DeleteDevice, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.DeviceServiceClient = DeviceServiceClient;

