import {
	SessionState,
	UPDATE_SESSION,
	NAV_CLOSE,
	NAV_TOGGLE,
	UpdateSessionAction,
	NavToggleAction,
	NavCloseAction,
} from './types';

export function updateSession(newSessionState: SessionState): UpdateSessionAction {
	return {
		type: UPDATE_SESSION,
		payload: newSessionState,
	};
}

export function setNavClosed(): NavCloseAction {
	return {
		type: NAV_CLOSE,
	};
}

export function toggleNavOpen(): NavToggleAction {
	return {
		type: NAV_TOGGLE,
	};
}
