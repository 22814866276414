import * as React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import WebFont from 'webfontloader';
import App from './main/view/App';
import reduxeStore from './main/redux/store';
import './index.css';
import registerServiceWorker from './main/util/registerServiceWorker';

const store = reduxeStore;

const Root = function Root(): React.ReactElement {
	return (
		<Provider store={store}>
			<App />
		</Provider>
	);
};

WebFont.load({
	google: {
		families: ['Roboto:300,400,700&display=swap', 'sans-serif'],
	},
});

render(<Root />, document.getElementById('root'));

registerServiceWorker();
