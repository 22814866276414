import {
	UPDATE_SESSION,
	SessionState,
	SessionActionTypes,
	NavOpenState,
	NavActionTypes,
	NAV_TOGGLE,
	NAV_CLOSE,
} from './types';

export const initialSessionState: SessionState = {
	loggedIn: false,
	sessionToken: '',
	userEmail: '',
	tokenExpiration: 0,
};

const initialOpenState: NavOpenState = {
	isOpen: false,
};

export function sessionReducer(
	// eslint-disable-next-line default-param-last
	state = initialSessionState,
	action: SessionActionTypes,
): SessionState {
	switch (action.type) {
		case UPDATE_SESSION: {
			return {
				...state,
				...action.payload,
			};
		}
		default:
			return state;
	}
}

export function navOpenStateReducer(
	// eslint-disable-next-line default-param-last
	state = initialOpenState,
	action: NavActionTypes,
): NavOpenState {
	switch (action.type) {
		case NAV_CLOSE:
			return {
				...state,
				isOpen: false,
			};
		case NAV_TOGGLE:
			return {
				...state,
				isOpen: !state.isOpen,
			};
		default:
			return state;
	}
}
