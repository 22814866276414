// source: account_service_def.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.com.addrobots.grpc.Account', null, global);
goog.exportSymbol('proto.com.addrobots.grpc.AccountReq', null, global);
goog.exportSymbol('proto.com.addrobots.grpc.AccountSearch', null, global);
goog.exportSymbol('proto.com.addrobots.grpc.CreateAccountReq', null, global);
goog.exportSymbol('proto.com.addrobots.grpc.CreateAccountResp', null, global);
goog.exportSymbol('proto.com.addrobots.grpc.CreateAccountResp.ResponseCode', null, global);
goog.exportSymbol('proto.com.addrobots.grpc.DeleteAccountResp', null, global);
goog.exportSymbol('proto.com.addrobots.grpc.DeleteAccountResp.ResponseCode', null, global);
goog.exportSymbol('proto.com.addrobots.grpc.FindAccountListResp', null, global);
goog.exportSymbol('proto.com.addrobots.grpc.FindAccountResp', null, global);
goog.exportSymbol('proto.com.addrobots.grpc.Location', null, global);
goog.exportSymbol('proto.com.addrobots.grpc.Organization', null, global);
goog.exportSymbol('proto.com.addrobots.grpc.UpdateAccountResp', null, global);
goog.exportSymbol('proto.com.addrobots.grpc.UpdateAccountResp.ResponseCode', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.addrobots.grpc.Account = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.addrobots.grpc.Account, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.addrobots.grpc.Account.displayName = 'proto.com.addrobots.grpc.Account';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.addrobots.grpc.AccountReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.addrobots.grpc.AccountReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.addrobots.grpc.AccountReq.displayName = 'proto.com.addrobots.grpc.AccountReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.addrobots.grpc.AccountSearch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.addrobots.grpc.AccountSearch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.addrobots.grpc.AccountSearch.displayName = 'proto.com.addrobots.grpc.AccountSearch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.addrobots.grpc.CreateAccountReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.addrobots.grpc.CreateAccountReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.addrobots.grpc.CreateAccountReq.displayName = 'proto.com.addrobots.grpc.CreateAccountReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.addrobots.grpc.CreateAccountResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.addrobots.grpc.CreateAccountResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.addrobots.grpc.CreateAccountResp.displayName = 'proto.com.addrobots.grpc.CreateAccountResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.addrobots.grpc.UpdateAccountResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.addrobots.grpc.UpdateAccountResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.addrobots.grpc.UpdateAccountResp.displayName = 'proto.com.addrobots.grpc.UpdateAccountResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.addrobots.grpc.DeleteAccountResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.addrobots.grpc.DeleteAccountResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.addrobots.grpc.DeleteAccountResp.displayName = 'proto.com.addrobots.grpc.DeleteAccountResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.addrobots.grpc.FindAccountResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.addrobots.grpc.FindAccountResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.addrobots.grpc.FindAccountResp.displayName = 'proto.com.addrobots.grpc.FindAccountResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.addrobots.grpc.FindAccountListResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.addrobots.grpc.FindAccountListResp.repeatedFields_, null);
};
goog.inherits(proto.com.addrobots.grpc.FindAccountListResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.addrobots.grpc.FindAccountListResp.displayName = 'proto.com.addrobots.grpc.FindAccountListResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.addrobots.grpc.Organization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.addrobots.grpc.Organization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.addrobots.grpc.Organization.displayName = 'proto.com.addrobots.grpc.Organization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.addrobots.grpc.Location = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.addrobots.grpc.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.addrobots.grpc.Location.displayName = 'proto.com.addrobots.grpc.Location';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.addrobots.grpc.Account.prototype.toObject = function(opt_includeInstance) {
  return proto.com.addrobots.grpc.Account.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.addrobots.grpc.Account} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.Account.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.addrobots.grpc.Account}
 */
proto.com.addrobots.grpc.Account.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.addrobots.grpc.Account;
  return proto.com.addrobots.grpc.Account.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.addrobots.grpc.Account} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.addrobots.grpc.Account}
 */
proto.com.addrobots.grpc.Account.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.addrobots.grpc.Account.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.addrobots.grpc.Account.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.addrobots.grpc.Account} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.Account.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.addrobots.grpc.Account.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.addrobots.grpc.Account} returns this
 */
proto.com.addrobots.grpc.Account.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.addrobots.grpc.Account.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.addrobots.grpc.Account} returns this
 */
proto.com.addrobots.grpc.Account.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.com.addrobots.grpc.Account.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.addrobots.grpc.Account} returns this
 */
proto.com.addrobots.grpc.Account.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.addrobots.grpc.AccountReq.prototype.toObject = function(opt_includeInstance) {
  return proto.com.addrobots.grpc.AccountReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.addrobots.grpc.AccountReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.AccountReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    reqHeader: (f = msg.getReqHeader()) && common_pb.RequestHeader.toObject(includeInstance, f),
    account: (f = msg.getAccount()) && proto.com.addrobots.grpc.Account.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.addrobots.grpc.AccountReq}
 */
proto.com.addrobots.grpc.AccountReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.addrobots.grpc.AccountReq;
  return proto.com.addrobots.grpc.AccountReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.addrobots.grpc.AccountReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.addrobots.grpc.AccountReq}
 */
proto.com.addrobots.grpc.AccountReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.RequestHeader;
      reader.readMessage(value,common_pb.RequestHeader.deserializeBinaryFromReader);
      msg.setReqHeader(value);
      break;
    case 2:
      var value = new proto.com.addrobots.grpc.Account;
      reader.readMessage(value,proto.com.addrobots.grpc.Account.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.addrobots.grpc.AccountReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.addrobots.grpc.AccountReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.addrobots.grpc.AccountReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.AccountReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReqHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.addrobots.grpc.Account.serializeBinaryToWriter
    );
  }
};


/**
 * optional com.addrobots.RequestHeader req_header = 1;
 * @return {?proto.com.addrobots.RequestHeader}
 */
proto.com.addrobots.grpc.AccountReq.prototype.getReqHeader = function() {
  return /** @type{?proto.com.addrobots.RequestHeader} */ (
    jspb.Message.getWrapperField(this, common_pb.RequestHeader, 1));
};


/**
 * @param {?proto.com.addrobots.RequestHeader|undefined} value
 * @return {!proto.com.addrobots.grpc.AccountReq} returns this
*/
proto.com.addrobots.grpc.AccountReq.prototype.setReqHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.AccountReq} returns this
 */
proto.com.addrobots.grpc.AccountReq.prototype.clearReqHeader = function() {
  return this.setReqHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.AccountReq.prototype.hasReqHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Account account = 2;
 * @return {?proto.com.addrobots.grpc.Account}
 */
proto.com.addrobots.grpc.AccountReq.prototype.getAccount = function() {
  return /** @type{?proto.com.addrobots.grpc.Account} */ (
    jspb.Message.getWrapperField(this, proto.com.addrobots.grpc.Account, 2));
};


/**
 * @param {?proto.com.addrobots.grpc.Account|undefined} value
 * @return {!proto.com.addrobots.grpc.AccountReq} returns this
*/
proto.com.addrobots.grpc.AccountReq.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.AccountReq} returns this
 */
proto.com.addrobots.grpc.AccountReq.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.AccountReq.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.addrobots.grpc.AccountSearch.prototype.toObject = function(opt_includeInstance) {
  return proto.com.addrobots.grpc.AccountSearch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.addrobots.grpc.AccountSearch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.AccountSearch.toObject = function(includeInstance, msg) {
  var f, obj = {
    reqHeader: (f = msg.getReqHeader()) && common_pb.RequestHeader.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.addrobots.grpc.AccountSearch}
 */
proto.com.addrobots.grpc.AccountSearch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.addrobots.grpc.AccountSearch;
  return proto.com.addrobots.grpc.AccountSearch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.addrobots.grpc.AccountSearch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.addrobots.grpc.AccountSearch}
 */
proto.com.addrobots.grpc.AccountSearch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.RequestHeader;
      reader.readMessage(value,common_pb.RequestHeader.deserializeBinaryFromReader);
      msg.setReqHeader(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.addrobots.grpc.AccountSearch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.addrobots.grpc.AccountSearch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.addrobots.grpc.AccountSearch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.AccountSearch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReqHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional com.addrobots.RequestHeader req_header = 1;
 * @return {?proto.com.addrobots.RequestHeader}
 */
proto.com.addrobots.grpc.AccountSearch.prototype.getReqHeader = function() {
  return /** @type{?proto.com.addrobots.RequestHeader} */ (
    jspb.Message.getWrapperField(this, common_pb.RequestHeader, 1));
};


/**
 * @param {?proto.com.addrobots.RequestHeader|undefined} value
 * @return {!proto.com.addrobots.grpc.AccountSearch} returns this
*/
proto.com.addrobots.grpc.AccountSearch.prototype.setReqHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.AccountSearch} returns this
 */
proto.com.addrobots.grpc.AccountSearch.prototype.clearReqHeader = function() {
  return this.setReqHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.AccountSearch.prototype.hasReqHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.com.addrobots.grpc.AccountSearch.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.addrobots.grpc.AccountSearch} returns this
 */
proto.com.addrobots.grpc.AccountSearch.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.addrobots.grpc.CreateAccountReq.prototype.toObject = function(opt_includeInstance) {
  return proto.com.addrobots.grpc.CreateAccountReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.addrobots.grpc.CreateAccountReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.CreateAccountReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    reqHeader: (f = msg.getReqHeader()) && common_pb.RequestHeader.toObject(includeInstance, f),
    inviteCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    oauthToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    account: (f = msg.getAccount()) && proto.com.addrobots.grpc.Account.toObject(includeInstance, f),
    organization: (f = msg.getOrganization()) && proto.com.addrobots.grpc.Organization.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && proto.com.addrobots.grpc.Location.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.addrobots.grpc.CreateAccountReq}
 */
proto.com.addrobots.grpc.CreateAccountReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.addrobots.grpc.CreateAccountReq;
  return proto.com.addrobots.grpc.CreateAccountReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.addrobots.grpc.CreateAccountReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.addrobots.grpc.CreateAccountReq}
 */
proto.com.addrobots.grpc.CreateAccountReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.RequestHeader;
      reader.readMessage(value,common_pb.RequestHeader.deserializeBinaryFromReader);
      msg.setReqHeader(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInviteCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOauthToken(value);
      break;
    case 4:
      var value = new proto.com.addrobots.grpc.Account;
      reader.readMessage(value,proto.com.addrobots.grpc.Account.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 5:
      var value = new proto.com.addrobots.grpc.Organization;
      reader.readMessage(value,proto.com.addrobots.grpc.Organization.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 6:
      var value = new proto.com.addrobots.grpc.Location;
      reader.readMessage(value,proto.com.addrobots.grpc.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.addrobots.grpc.CreateAccountReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.addrobots.grpc.CreateAccountReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.addrobots.grpc.CreateAccountReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.CreateAccountReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReqHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getInviteCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOauthToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.addrobots.grpc.Account.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.addrobots.grpc.Organization.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.com.addrobots.grpc.Location.serializeBinaryToWriter
    );
  }
};


/**
 * optional com.addrobots.RequestHeader req_header = 1;
 * @return {?proto.com.addrobots.RequestHeader}
 */
proto.com.addrobots.grpc.CreateAccountReq.prototype.getReqHeader = function() {
  return /** @type{?proto.com.addrobots.RequestHeader} */ (
    jspb.Message.getWrapperField(this, common_pb.RequestHeader, 1));
};


/**
 * @param {?proto.com.addrobots.RequestHeader|undefined} value
 * @return {!proto.com.addrobots.grpc.CreateAccountReq} returns this
*/
proto.com.addrobots.grpc.CreateAccountReq.prototype.setReqHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.CreateAccountReq} returns this
 */
proto.com.addrobots.grpc.CreateAccountReq.prototype.clearReqHeader = function() {
  return this.setReqHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.CreateAccountReq.prototype.hasReqHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string invite_code = 2;
 * @return {string}
 */
proto.com.addrobots.grpc.CreateAccountReq.prototype.getInviteCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.addrobots.grpc.CreateAccountReq} returns this
 */
proto.com.addrobots.grpc.CreateAccountReq.prototype.setInviteCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string oauth_token = 3;
 * @return {string}
 */
proto.com.addrobots.grpc.CreateAccountReq.prototype.getOauthToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.addrobots.grpc.CreateAccountReq} returns this
 */
proto.com.addrobots.grpc.CreateAccountReq.prototype.setOauthToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Account account = 4;
 * @return {?proto.com.addrobots.grpc.Account}
 */
proto.com.addrobots.grpc.CreateAccountReq.prototype.getAccount = function() {
  return /** @type{?proto.com.addrobots.grpc.Account} */ (
    jspb.Message.getWrapperField(this, proto.com.addrobots.grpc.Account, 4));
};


/**
 * @param {?proto.com.addrobots.grpc.Account|undefined} value
 * @return {!proto.com.addrobots.grpc.CreateAccountReq} returns this
*/
proto.com.addrobots.grpc.CreateAccountReq.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.CreateAccountReq} returns this
 */
proto.com.addrobots.grpc.CreateAccountReq.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.CreateAccountReq.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Organization organization = 5;
 * @return {?proto.com.addrobots.grpc.Organization}
 */
proto.com.addrobots.grpc.CreateAccountReq.prototype.getOrganization = function() {
  return /** @type{?proto.com.addrobots.grpc.Organization} */ (
    jspb.Message.getWrapperField(this, proto.com.addrobots.grpc.Organization, 5));
};


/**
 * @param {?proto.com.addrobots.grpc.Organization|undefined} value
 * @return {!proto.com.addrobots.grpc.CreateAccountReq} returns this
*/
proto.com.addrobots.grpc.CreateAccountReq.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.CreateAccountReq} returns this
 */
proto.com.addrobots.grpc.CreateAccountReq.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.CreateAccountReq.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Location location = 6;
 * @return {?proto.com.addrobots.grpc.Location}
 */
proto.com.addrobots.grpc.CreateAccountReq.prototype.getLocation = function() {
  return /** @type{?proto.com.addrobots.grpc.Location} */ (
    jspb.Message.getWrapperField(this, proto.com.addrobots.grpc.Location, 6));
};


/**
 * @param {?proto.com.addrobots.grpc.Location|undefined} value
 * @return {!proto.com.addrobots.grpc.CreateAccountReq} returns this
*/
proto.com.addrobots.grpc.CreateAccountReq.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.CreateAccountReq} returns this
 */
proto.com.addrobots.grpc.CreateAccountReq.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.CreateAccountReq.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.addrobots.grpc.CreateAccountResp.prototype.toObject = function(opt_includeInstance) {
  return proto.com.addrobots.grpc.CreateAccountResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.addrobots.grpc.CreateAccountResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.CreateAccountResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    respHeader: (f = msg.getRespHeader()) && common_pb.ResponseHeader.toObject(includeInstance, f),
    responseCode: jspb.Message.getFieldWithDefault(msg, 2, 0),
    account: (f = msg.getAccount()) && proto.com.addrobots.grpc.Account.toObject(includeInstance, f),
    organization: (f = msg.getOrganization()) && proto.com.addrobots.grpc.Organization.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && proto.com.addrobots.grpc.Location.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.addrobots.grpc.CreateAccountResp}
 */
proto.com.addrobots.grpc.CreateAccountResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.addrobots.grpc.CreateAccountResp;
  return proto.com.addrobots.grpc.CreateAccountResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.addrobots.grpc.CreateAccountResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.addrobots.grpc.CreateAccountResp}
 */
proto.com.addrobots.grpc.CreateAccountResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ResponseHeader;
      reader.readMessage(value,common_pb.ResponseHeader.deserializeBinaryFromReader);
      msg.setRespHeader(value);
      break;
    case 2:
      var value = /** @type {!proto.com.addrobots.grpc.CreateAccountResp.ResponseCode} */ (reader.readEnum());
      msg.setResponseCode(value);
      break;
    case 3:
      var value = new proto.com.addrobots.grpc.Account;
      reader.readMessage(value,proto.com.addrobots.grpc.Account.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 4:
      var value = new proto.com.addrobots.grpc.Organization;
      reader.readMessage(value,proto.com.addrobots.grpc.Organization.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 5:
      var value = new proto.com.addrobots.grpc.Location;
      reader.readMessage(value,proto.com.addrobots.grpc.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.addrobots.grpc.CreateAccountResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.addrobots.grpc.CreateAccountResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.addrobots.grpc.CreateAccountResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.CreateAccountResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRespHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getResponseCode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.addrobots.grpc.Account.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.addrobots.grpc.Organization.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.addrobots.grpc.Location.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.com.addrobots.grpc.CreateAccountResp.ResponseCode = {
  UNKNOWN: 0,
  SUCCESS: 1,
  FAILURE: 2
};

/**
 * optional com.addrobots.ResponseHeader resp_header = 1;
 * @return {?proto.com.addrobots.ResponseHeader}
 */
proto.com.addrobots.grpc.CreateAccountResp.prototype.getRespHeader = function() {
  return /** @type{?proto.com.addrobots.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, common_pb.ResponseHeader, 1));
};


/**
 * @param {?proto.com.addrobots.ResponseHeader|undefined} value
 * @return {!proto.com.addrobots.grpc.CreateAccountResp} returns this
*/
proto.com.addrobots.grpc.CreateAccountResp.prototype.setRespHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.CreateAccountResp} returns this
 */
proto.com.addrobots.grpc.CreateAccountResp.prototype.clearRespHeader = function() {
  return this.setRespHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.CreateAccountResp.prototype.hasRespHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResponseCode response_code = 2;
 * @return {!proto.com.addrobots.grpc.CreateAccountResp.ResponseCode}
 */
proto.com.addrobots.grpc.CreateAccountResp.prototype.getResponseCode = function() {
  return /** @type {!proto.com.addrobots.grpc.CreateAccountResp.ResponseCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.addrobots.grpc.CreateAccountResp.ResponseCode} value
 * @return {!proto.com.addrobots.grpc.CreateAccountResp} returns this
 */
proto.com.addrobots.grpc.CreateAccountResp.prototype.setResponseCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Account account = 3;
 * @return {?proto.com.addrobots.grpc.Account}
 */
proto.com.addrobots.grpc.CreateAccountResp.prototype.getAccount = function() {
  return /** @type{?proto.com.addrobots.grpc.Account} */ (
    jspb.Message.getWrapperField(this, proto.com.addrobots.grpc.Account, 3));
};


/**
 * @param {?proto.com.addrobots.grpc.Account|undefined} value
 * @return {!proto.com.addrobots.grpc.CreateAccountResp} returns this
*/
proto.com.addrobots.grpc.CreateAccountResp.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.CreateAccountResp} returns this
 */
proto.com.addrobots.grpc.CreateAccountResp.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.CreateAccountResp.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Organization organization = 4;
 * @return {?proto.com.addrobots.grpc.Organization}
 */
proto.com.addrobots.grpc.CreateAccountResp.prototype.getOrganization = function() {
  return /** @type{?proto.com.addrobots.grpc.Organization} */ (
    jspb.Message.getWrapperField(this, proto.com.addrobots.grpc.Organization, 4));
};


/**
 * @param {?proto.com.addrobots.grpc.Organization|undefined} value
 * @return {!proto.com.addrobots.grpc.CreateAccountResp} returns this
*/
proto.com.addrobots.grpc.CreateAccountResp.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.CreateAccountResp} returns this
 */
proto.com.addrobots.grpc.CreateAccountResp.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.CreateAccountResp.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Location location = 5;
 * @return {?proto.com.addrobots.grpc.Location}
 */
proto.com.addrobots.grpc.CreateAccountResp.prototype.getLocation = function() {
  return /** @type{?proto.com.addrobots.grpc.Location} */ (
    jspb.Message.getWrapperField(this, proto.com.addrobots.grpc.Location, 5));
};


/**
 * @param {?proto.com.addrobots.grpc.Location|undefined} value
 * @return {!proto.com.addrobots.grpc.CreateAccountResp} returns this
*/
proto.com.addrobots.grpc.CreateAccountResp.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.CreateAccountResp} returns this
 */
proto.com.addrobots.grpc.CreateAccountResp.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.CreateAccountResp.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.addrobots.grpc.UpdateAccountResp.prototype.toObject = function(opt_includeInstance) {
  return proto.com.addrobots.grpc.UpdateAccountResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.addrobots.grpc.UpdateAccountResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.UpdateAccountResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    respHeader: (f = msg.getRespHeader()) && common_pb.ResponseHeader.toObject(includeInstance, f),
    responseCode: jspb.Message.getFieldWithDefault(msg, 2, 0),
    account: (f = msg.getAccount()) && proto.com.addrobots.grpc.Account.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.addrobots.grpc.UpdateAccountResp}
 */
proto.com.addrobots.grpc.UpdateAccountResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.addrobots.grpc.UpdateAccountResp;
  return proto.com.addrobots.grpc.UpdateAccountResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.addrobots.grpc.UpdateAccountResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.addrobots.grpc.UpdateAccountResp}
 */
proto.com.addrobots.grpc.UpdateAccountResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ResponseHeader;
      reader.readMessage(value,common_pb.ResponseHeader.deserializeBinaryFromReader);
      msg.setRespHeader(value);
      break;
    case 2:
      var value = /** @type {!proto.com.addrobots.grpc.UpdateAccountResp.ResponseCode} */ (reader.readEnum());
      msg.setResponseCode(value);
      break;
    case 3:
      var value = new proto.com.addrobots.grpc.Account;
      reader.readMessage(value,proto.com.addrobots.grpc.Account.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.addrobots.grpc.UpdateAccountResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.addrobots.grpc.UpdateAccountResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.addrobots.grpc.UpdateAccountResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.UpdateAccountResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRespHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getResponseCode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.addrobots.grpc.Account.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.com.addrobots.grpc.UpdateAccountResp.ResponseCode = {
  UNKNOWN: 0,
  SUCCESS: 1,
  PERMISSION_DENIED: 2
};

/**
 * optional com.addrobots.ResponseHeader resp_header = 1;
 * @return {?proto.com.addrobots.ResponseHeader}
 */
proto.com.addrobots.grpc.UpdateAccountResp.prototype.getRespHeader = function() {
  return /** @type{?proto.com.addrobots.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, common_pb.ResponseHeader, 1));
};


/**
 * @param {?proto.com.addrobots.ResponseHeader|undefined} value
 * @return {!proto.com.addrobots.grpc.UpdateAccountResp} returns this
*/
proto.com.addrobots.grpc.UpdateAccountResp.prototype.setRespHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.UpdateAccountResp} returns this
 */
proto.com.addrobots.grpc.UpdateAccountResp.prototype.clearRespHeader = function() {
  return this.setRespHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.UpdateAccountResp.prototype.hasRespHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResponseCode response_code = 2;
 * @return {!proto.com.addrobots.grpc.UpdateAccountResp.ResponseCode}
 */
proto.com.addrobots.grpc.UpdateAccountResp.prototype.getResponseCode = function() {
  return /** @type {!proto.com.addrobots.grpc.UpdateAccountResp.ResponseCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.addrobots.grpc.UpdateAccountResp.ResponseCode} value
 * @return {!proto.com.addrobots.grpc.UpdateAccountResp} returns this
 */
proto.com.addrobots.grpc.UpdateAccountResp.prototype.setResponseCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Account account = 3;
 * @return {?proto.com.addrobots.grpc.Account}
 */
proto.com.addrobots.grpc.UpdateAccountResp.prototype.getAccount = function() {
  return /** @type{?proto.com.addrobots.grpc.Account} */ (
    jspb.Message.getWrapperField(this, proto.com.addrobots.grpc.Account, 3));
};


/**
 * @param {?proto.com.addrobots.grpc.Account|undefined} value
 * @return {!proto.com.addrobots.grpc.UpdateAccountResp} returns this
*/
proto.com.addrobots.grpc.UpdateAccountResp.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.UpdateAccountResp} returns this
 */
proto.com.addrobots.grpc.UpdateAccountResp.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.UpdateAccountResp.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.addrobots.grpc.DeleteAccountResp.prototype.toObject = function(opt_includeInstance) {
  return proto.com.addrobots.grpc.DeleteAccountResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.addrobots.grpc.DeleteAccountResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.DeleteAccountResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    respHeader: (f = msg.getRespHeader()) && common_pb.ResponseHeader.toObject(includeInstance, f),
    responseCode: jspb.Message.getFieldWithDefault(msg, 2, 0),
    account: (f = msg.getAccount()) && proto.com.addrobots.grpc.Account.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.addrobots.grpc.DeleteAccountResp}
 */
proto.com.addrobots.grpc.DeleteAccountResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.addrobots.grpc.DeleteAccountResp;
  return proto.com.addrobots.grpc.DeleteAccountResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.addrobots.grpc.DeleteAccountResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.addrobots.grpc.DeleteAccountResp}
 */
proto.com.addrobots.grpc.DeleteAccountResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ResponseHeader;
      reader.readMessage(value,common_pb.ResponseHeader.deserializeBinaryFromReader);
      msg.setRespHeader(value);
      break;
    case 2:
      var value = /** @type {!proto.com.addrobots.grpc.DeleteAccountResp.ResponseCode} */ (reader.readEnum());
      msg.setResponseCode(value);
      break;
    case 3:
      var value = new proto.com.addrobots.grpc.Account;
      reader.readMessage(value,proto.com.addrobots.grpc.Account.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.addrobots.grpc.DeleteAccountResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.addrobots.grpc.DeleteAccountResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.addrobots.grpc.DeleteAccountResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.DeleteAccountResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRespHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getResponseCode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.addrobots.grpc.Account.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.com.addrobots.grpc.DeleteAccountResp.ResponseCode = {
  UNKNOWN: 0,
  SUCCESS: 1,
  FAILED: 2
};

/**
 * optional com.addrobots.ResponseHeader resp_header = 1;
 * @return {?proto.com.addrobots.ResponseHeader}
 */
proto.com.addrobots.grpc.DeleteAccountResp.prototype.getRespHeader = function() {
  return /** @type{?proto.com.addrobots.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, common_pb.ResponseHeader, 1));
};


/**
 * @param {?proto.com.addrobots.ResponseHeader|undefined} value
 * @return {!proto.com.addrobots.grpc.DeleteAccountResp} returns this
*/
proto.com.addrobots.grpc.DeleteAccountResp.prototype.setRespHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.DeleteAccountResp} returns this
 */
proto.com.addrobots.grpc.DeleteAccountResp.prototype.clearRespHeader = function() {
  return this.setRespHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.DeleteAccountResp.prototype.hasRespHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResponseCode response_code = 2;
 * @return {!proto.com.addrobots.grpc.DeleteAccountResp.ResponseCode}
 */
proto.com.addrobots.grpc.DeleteAccountResp.prototype.getResponseCode = function() {
  return /** @type {!proto.com.addrobots.grpc.DeleteAccountResp.ResponseCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.addrobots.grpc.DeleteAccountResp.ResponseCode} value
 * @return {!proto.com.addrobots.grpc.DeleteAccountResp} returns this
 */
proto.com.addrobots.grpc.DeleteAccountResp.prototype.setResponseCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Account account = 3;
 * @return {?proto.com.addrobots.grpc.Account}
 */
proto.com.addrobots.grpc.DeleteAccountResp.prototype.getAccount = function() {
  return /** @type{?proto.com.addrobots.grpc.Account} */ (
    jspb.Message.getWrapperField(this, proto.com.addrobots.grpc.Account, 3));
};


/**
 * @param {?proto.com.addrobots.grpc.Account|undefined} value
 * @return {!proto.com.addrobots.grpc.DeleteAccountResp} returns this
*/
proto.com.addrobots.grpc.DeleteAccountResp.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.DeleteAccountResp} returns this
 */
proto.com.addrobots.grpc.DeleteAccountResp.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.DeleteAccountResp.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.addrobots.grpc.FindAccountResp.prototype.toObject = function(opt_includeInstance) {
  return proto.com.addrobots.grpc.FindAccountResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.addrobots.grpc.FindAccountResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.FindAccountResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    respHeader: (f = msg.getRespHeader()) && common_pb.ResponseHeader.toObject(includeInstance, f),
    account: (f = msg.getAccount()) && proto.com.addrobots.grpc.Account.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.addrobots.grpc.FindAccountResp}
 */
proto.com.addrobots.grpc.FindAccountResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.addrobots.grpc.FindAccountResp;
  return proto.com.addrobots.grpc.FindAccountResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.addrobots.grpc.FindAccountResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.addrobots.grpc.FindAccountResp}
 */
proto.com.addrobots.grpc.FindAccountResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ResponseHeader;
      reader.readMessage(value,common_pb.ResponseHeader.deserializeBinaryFromReader);
      msg.setRespHeader(value);
      break;
    case 2:
      var value = new proto.com.addrobots.grpc.Account;
      reader.readMessage(value,proto.com.addrobots.grpc.Account.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.addrobots.grpc.FindAccountResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.addrobots.grpc.FindAccountResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.addrobots.grpc.FindAccountResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.FindAccountResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRespHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.addrobots.grpc.Account.serializeBinaryToWriter
    );
  }
};


/**
 * optional com.addrobots.ResponseHeader resp_header = 1;
 * @return {?proto.com.addrobots.ResponseHeader}
 */
proto.com.addrobots.grpc.FindAccountResp.prototype.getRespHeader = function() {
  return /** @type{?proto.com.addrobots.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, common_pb.ResponseHeader, 1));
};


/**
 * @param {?proto.com.addrobots.ResponseHeader|undefined} value
 * @return {!proto.com.addrobots.grpc.FindAccountResp} returns this
*/
proto.com.addrobots.grpc.FindAccountResp.prototype.setRespHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.FindAccountResp} returns this
 */
proto.com.addrobots.grpc.FindAccountResp.prototype.clearRespHeader = function() {
  return this.setRespHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.FindAccountResp.prototype.hasRespHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Account account = 2;
 * @return {?proto.com.addrobots.grpc.Account}
 */
proto.com.addrobots.grpc.FindAccountResp.prototype.getAccount = function() {
  return /** @type{?proto.com.addrobots.grpc.Account} */ (
    jspb.Message.getWrapperField(this, proto.com.addrobots.grpc.Account, 2));
};


/**
 * @param {?proto.com.addrobots.grpc.Account|undefined} value
 * @return {!proto.com.addrobots.grpc.FindAccountResp} returns this
*/
proto.com.addrobots.grpc.FindAccountResp.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.FindAccountResp} returns this
 */
proto.com.addrobots.grpc.FindAccountResp.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.FindAccountResp.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.addrobots.grpc.FindAccountListResp.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.addrobots.grpc.FindAccountListResp.prototype.toObject = function(opt_includeInstance) {
  return proto.com.addrobots.grpc.FindAccountListResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.addrobots.grpc.FindAccountListResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.FindAccountListResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    respHeader: (f = msg.getRespHeader()) && common_pb.ResponseHeader.toObject(includeInstance, f),
    accountList: jspb.Message.toObjectList(msg.getAccountList(),
    proto.com.addrobots.grpc.Account.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.addrobots.grpc.FindAccountListResp}
 */
proto.com.addrobots.grpc.FindAccountListResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.addrobots.grpc.FindAccountListResp;
  return proto.com.addrobots.grpc.FindAccountListResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.addrobots.grpc.FindAccountListResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.addrobots.grpc.FindAccountListResp}
 */
proto.com.addrobots.grpc.FindAccountListResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ResponseHeader;
      reader.readMessage(value,common_pb.ResponseHeader.deserializeBinaryFromReader);
      msg.setRespHeader(value);
      break;
    case 2:
      var value = new proto.com.addrobots.grpc.Account;
      reader.readMessage(value,proto.com.addrobots.grpc.Account.deserializeBinaryFromReader);
      msg.addAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.addrobots.grpc.FindAccountListResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.addrobots.grpc.FindAccountListResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.addrobots.grpc.FindAccountListResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.FindAccountListResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRespHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getAccountList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.addrobots.grpc.Account.serializeBinaryToWriter
    );
  }
};


/**
 * optional com.addrobots.ResponseHeader resp_header = 1;
 * @return {?proto.com.addrobots.ResponseHeader}
 */
proto.com.addrobots.grpc.FindAccountListResp.prototype.getRespHeader = function() {
  return /** @type{?proto.com.addrobots.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, common_pb.ResponseHeader, 1));
};


/**
 * @param {?proto.com.addrobots.ResponseHeader|undefined} value
 * @return {!proto.com.addrobots.grpc.FindAccountListResp} returns this
*/
proto.com.addrobots.grpc.FindAccountListResp.prototype.setRespHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.FindAccountListResp} returns this
 */
proto.com.addrobots.grpc.FindAccountListResp.prototype.clearRespHeader = function() {
  return this.setRespHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.FindAccountListResp.prototype.hasRespHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Account account = 2;
 * @return {!Array<!proto.com.addrobots.grpc.Account>}
 */
proto.com.addrobots.grpc.FindAccountListResp.prototype.getAccountList = function() {
  return /** @type{!Array<!proto.com.addrobots.grpc.Account>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.addrobots.grpc.Account, 2));
};


/**
 * @param {!Array<!proto.com.addrobots.grpc.Account>} value
 * @return {!proto.com.addrobots.grpc.FindAccountListResp} returns this
*/
proto.com.addrobots.grpc.FindAccountListResp.prototype.setAccountList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.addrobots.grpc.Account=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.addrobots.grpc.Account}
 */
proto.com.addrobots.grpc.FindAccountListResp.prototype.addAccount = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.addrobots.grpc.Account, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.addrobots.grpc.FindAccountListResp} returns this
 */
proto.com.addrobots.grpc.FindAccountListResp.prototype.clearAccountList = function() {
  return this.setAccountList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.addrobots.grpc.Organization.prototype.toObject = function(opt_includeInstance) {
  return proto.com.addrobots.grpc.Organization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.addrobots.grpc.Organization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.Organization.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.addrobots.grpc.Organization}
 */
proto.com.addrobots.grpc.Organization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.addrobots.grpc.Organization;
  return proto.com.addrobots.grpc.Organization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.addrobots.grpc.Organization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.addrobots.grpc.Organization}
 */
proto.com.addrobots.grpc.Organization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.addrobots.grpc.Organization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.addrobots.grpc.Organization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.addrobots.grpc.Organization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.Organization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.addrobots.grpc.Organization.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.addrobots.grpc.Organization} returns this
 */
proto.com.addrobots.grpc.Organization.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.addrobots.grpc.Location.prototype.toObject = function(opt_includeInstance) {
  return proto.com.addrobots.grpc.Location.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.addrobots.grpc.Location} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.Location.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.addrobots.grpc.Location}
 */
proto.com.addrobots.grpc.Location.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.addrobots.grpc.Location;
  return proto.com.addrobots.grpc.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.addrobots.grpc.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.addrobots.grpc.Location}
 */
proto.com.addrobots.grpc.Location.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.addrobots.grpc.Location.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.addrobots.grpc.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.addrobots.grpc.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.Location.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.addrobots.grpc.Location.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.addrobots.grpc.Location} returns this
 */
proto.com.addrobots.grpc.Location.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.com.addrobots.grpc);
