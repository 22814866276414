import { Metadata } from '@addrobots/grpc-web/dist/typings/metadata';
import { grpc } from '@addrobots/grpc-web';
import GrpcClient from './GrpcClient';
import { AuthReq, AuthResp } from '../../generated/main/grpcweb/auth_service_def_pb';
import { AuthService } from '../../generated/main/grpcweb/auth_service_def_pb_service';
import { Header, RequestHeader } from '../../generated/main/grpcweb/common_pb';
import { updateSession } from '../redux/system/actions';
import reduxStore from '../redux/store';
import Config from './Config';

export interface StoredSession {
	token: string;
	expiresAt: number;
	email: string;
}

export default class GoogleAuth {
	private static _singleton: GoogleAuth;

	private auth2: gapi.auth2.GoogleAuth;

	private grpcClient: GrpcClient;

	private sessionToken = reduxStore.getState().session.sessionToken;

	private static onHeadersFn(headers: Metadata): void {
		console.log('GrpcClient onHeaders: ', headers);
	}

	constructor() {
		this.grpcClient = new GrpcClient(GoogleAuth.onHeadersFn).setupClientNonStreaming(
			AuthService.Login,
		);
	}

	public static get Instance(): GoogleAuth {
		// eslint-disable-next-line no-underscore-dangle,no-return-assign
		return this._singleton || (this._singleton = new this());
	}

	// eslint-disable-next-line class-methods-use-this
	private verifyAuth2(callback: () => void): void {
		if (gapi !== undefined && gapi != null) {
			if (GoogleAuth.Instance.auth2 === null || GoogleAuth.Instance.auth2 === undefined) {
				gapi.load('client', () => {
					const clientConfig: gapi.auth2.ClientConfig = {
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						client_id:
							'1003877940549-6t3tel1dn709872phvqf2bg5duq516gc.apps.googleusercontent.com',
						scope: 'profile',
					};

					GoogleAuth.Instance.auth2 = gapi.auth2.init(clientConfig);

					if (!GoogleAuth.Instance.auth2.isSignedIn.get()) {
						updateSession({
							loggedIn: false,
							sessionToken: '',
							userEmail: '',
							tokenExpiration: 0,
						});
					}
					localStorage.removeItem('currentSessionToken');
					GoogleAuth.Instance.auth2.signIn().then(callback);
				});
				// } else {
				// 	GoogleAuth.Instance.auth2.signIn().then(callback);
			}
		}
	}

	// eslint-disable-next-line class-methods-use-this
	private loginUser(user: gapi.auth2.GoogleUser): void {
		console.log('User now: ', user);
		const googleUser = GoogleAuth.Instance.auth2.currentUser.get();
		const id = googleUser.getId();
		const auth = googleUser.getAuthResponse();
		console.log(`google login uid: ${id}, auth: ${auth}`);

		const profile = googleUser.getBasicProfile();
		console.log(`ID: ${profile.getId()}`);
		console.log(`Full Name: ${profile.getName()}`);
		console.log(`Given Name: ${profile.getGivenName()}`);
		console.log(`Family Name: ${profile.getFamilyName()}`);
		console.log(`Image URL: ${profile.getImageUrl()}`);
		console.log(`Email: ${profile.getEmail()}`);

		reduxStore.dispatch(
			updateSession({
				loggedIn: false,
				sessionToken: auth.id_token,
				userEmail: profile.getEmail(),
				tokenExpiration: auth.expires_at,
			}),
		);

		const header = new Header();
		header.setMsgTransactionUuid('12345678');
		const reqHeader = new RequestHeader();
		reqHeader.setHeader(header);

		const tokenParam: AuthReq.Param = new AuthReq.Param();
		tokenParam.setId(AuthReq.Param.Id.TOKEN);
		tokenParam.setValue(auth.id_token);

		const authReq: AuthReq = new AuthReq();
		authReq.setReqHeader(reqHeader);
		authReq.setAction(AuthReq.Action.LOGIN);
		authReq.addParams(tokenParam, 0);

		GoogleAuth.Instance.grpcClient.sendMsgUnary(AuthService.Login, {
			host: Config.AppServerUrl,
			transport: grpc.CrossBrowserHttpTransport({ withCredentials: true }),
			metadata: new grpc.Metadata({ 'x-auth-token': GoogleAuth.Instance.sessionToken }),
			request: authReq,
			onEnd: ({ status, statusMessage, headers, message, trailers }) => {
				const authResp: AuthResp = message as AuthResp;
				console.log(
					'Login reply: ',
					authResp,
					'status: ',
					status,
					'statusMsg: ',
					statusMessage,
					'headers: ',
					headers,
					'trailers: ',
					trailers,
				);
				if (authResp.getStatus() === AuthResp.Status.SUCCESSFUL) {
					reduxStore.dispatch(
						updateSession({
							loggedIn: true,
							sessionToken: auth.id_token,
							userEmail: profile.getEmail(),
							tokenExpiration: auth.expires_at,
						}),
					);
					const storedSession: StoredSession = {
						token: auth.id_token,
						expiresAt: auth.expires_at,
						email: profile.getEmail(),
					};
					localStorage.setItem('currentSessionToken', JSON.stringify(storedSession));
				}
			},
		});
	}

	// eslint-disable-next-line class-methods-use-this
	public login(): void {
		GoogleAuth.Instance.verifyAuth2(() => {
			console.log('User login: ', GoogleAuth.Instance.auth2.currentUser.get().getId());
			GoogleAuth.Instance.loginUser(GoogleAuth.Instance.auth2.currentUser.get());
		});
	}

	// eslint-disable-next-line class-methods-use-this
	private logoutUser(): void {
		const action = AuthReq.Action.LOGOUT;
		const authReq = new AuthReq();
		authReq.setAction(action);

		GoogleAuth.Instance.grpcClient.sendMsgUnary(AuthService.Login, {
			host: Config.AppServerUrl,
			transport: grpc.CrossBrowserHttpTransport({ withCredentials: true }),
			metadata: new grpc.Metadata({ 'x-auth-token': GoogleAuth.Instance.sessionToken }),
			request: authReq,
			onEnd: ({ status, statusMessage, headers, message, trailers }) => {
				const authResp: AuthResp = message as AuthResp;
				console.log(
					'Logout reply: ',
					authResp,
					'status: ',
					status,
					'statusMsg: ',
					statusMessage,
					'headers: ',
					headers,
					'trailers: ',
					trailers,
				);
				// if (authResp.getStatus() === AuthResp.Status.SUCCESSFUL) {
				reduxStore.dispatch(
					updateSession({
						loggedIn: false,
						sessionToken: '',
						userEmail: '',
						tokenExpiration: 0,
					}),
				);
				localStorage.removeItem('currentSessionToken');
				// }
			},
		});
	}

	// eslint-disable-next-line class-methods-use-this
	public logout(): void {
		if (
			GoogleAuth.Instance.sessionToken != null &&
			GoogleAuth.Instance.sessionToken.length > 0
		) {
			GoogleAuth.Instance.verifyAuth2(() => {
				GoogleAuth.Instance.auth2.signOut().then(() => {
					console.log('User logout: ');
					GoogleAuth.Instance.logoutUser();
				});
			});
		}
	}

	// eslint-disable-next-line class-methods-use-this
	public restorePreviousLogin(): void {
		const storedSessionString: string | null = localStorage.getItem('currentSessionToken');
		if (storedSessionString != null) {
			const session: StoredSession = JSON.parse(storedSessionString);
			if (session.expiresAt > Date.now()) {
				reduxStore.dispatch(
					updateSession({
						loggedIn: true,
						sessionToken: session.token,
						userEmail: session.email,
						tokenExpiration: session.expiresAt,
					}),
				);
			} else {
				localStorage.removeItem('currentSessionToken');
				GoogleAuth.Instance.logout();
			}
		}
	}
}
