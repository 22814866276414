// eslint-disable-next-line max-classes-per-file
import { lazy, Suspense } from 'react';

import * as React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import NavBar from './NavBar';
import useMainTheme from '../styles/mainTheme';
import RobotController from './RobotController';

declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

const App = function App(): JSX.Element {
	const Home = lazy(() => import('./Home'));
	const RobotInventory = lazy(() => import('./RobotInventory'));
	const RobotEdit = lazy(() => import('./RobotEdit'));
	const Info = lazy(() => import('./Info'));
	const Invite = lazy(() => import('./Invite'));

	// there's a problem with react hooks and webpack
	// this issue tracks it: https://github.com/welldone-software/why-did-you-render/issues/85
	// we tried a few things, and they work for react-redux, but not material-ui
	// once it's figured out, remove the "x-" below
	if (process.env.NODE_ENV === 'x-development') {
		whyDidYouRender(React, {
			trackAllPureComponents: true,
			trackExtraHooks: [
				// eslint-disable-next-line global-require
				[require('react-redux'), 'useSelector'],
				// eslint-disable-next-line global-require
				[require('@mui/material/styles'), 'makeStyles'],
			],
		});
	}

	return (
		<React.StrictMode>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={useMainTheme()}>
					<div style={{ textAlign: 'center' }}>
						<NavBar />
						<Suspense fallback={<div>Loading... </div>}>
							<Router>
								<RouterPage path="/" pageComponent={<Home />} />
								<RobotInventory path="/robot/inventory" />
								<RobotEdit path="/robot/edit" />
								<RobotEdit path="/robot/edit/:robotId" />
								<RobotController path="/robot/controller/:robotId" />
								<RouterPage path="/info" pageComponent={<Info />} />
								<Invite path="/invite/:inviteCode" />
							</Router>
						</Suspense>
					</div>
				</ThemeProvider>
			</StyledEngineProvider>
		</React.StrictMode>
	);
};

const RouterPage = (props: { pageComponent: JSX.Element } & RouteComponentProps): JSX.Element =>
	props.pageComponent;

export default App;
