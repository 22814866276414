import { grpc } from '@addrobots/grpc-web';
import { Metadata } from '@addrobots/grpc-web/dist/typings/metadata';
import { ProtobufMessage } from '@addrobots/grpc-web/dist/typings/message';
import Config from './Config';

export default class GrpcClient {
	private isOpen = false;

	private client: grpc.Client<grpc.ProtobufMessage, grpc.ProtobufMessage>;

	private readonly onHeadersFn: (headers: Metadata) => void;

	constructor(onHeadersFn: (headers: Metadata) => void) {
		this.onHeadersFn = onHeadersFn;
	}

	private onEnd(status: grpc.Code, statusMessage: string, trailers: grpc.Metadata): void {
		console.log('Grpc client end: ', status, statusMessage, trailers);
		this.isOpen = false;
	}

	private onError(status: grpc.Code, statusMessage: string, trailers: grpc.Metadata): void {
		console.log(
			'Grpc client error: ',
			(this && this.isOpen) || false,
			status,
			statusMessage,
			trailers,
		);
	}

	public setupClientNonStreaming(
		service: grpc.MethodDefinition<ProtobufMessage, ProtobufMessage>,
	): GrpcClient {
		this.setupClient(service, {
			host: Config.AppServerUrl,
			transport: grpc.CrossBrowserHttpTransport({ withCredentials: true }),
			debug: true,
		});
		return this;
	}

	public setupClientStreaming(
		service: grpc.MethodDefinition<ProtobufMessage, ProtobufMessage>,
	): GrpcClient {
		this.setupClient(service, {
			host: Config.AppServerUrl,
			transport: grpc.WebsocketTransport(),
			debug: true,
		});
		return this;
	}

	private setupClient(
		service: grpc.MethodDefinition<ProtobufMessage, ProtobufMessage>,
		props: grpc.ClientRpcOptions,
	): void {
		this.client = grpc.client(service, props);

		this.client.onEnd(this.onEnd);
		this.client.onError(this.onError);
		this.client.onHeaders(this.onHeadersFn);
	}

	public startClient(sessionType: string, metaData: grpc.Metadata): GrpcClient {
		this.client.start(metaData);
		this.isOpen = true;
		return this;
	}

	public terminateClient(): GrpcClient {
		if (this.isOpen) {
			this.client.finishSend();
			this.client.close();
		}
		return this;
	}

	public sendMsg(msg: grpc.ProtobufMessage): void {
		console.log('Grpc send: ', msg);
		this.client.send(msg);
	}

	public sendMsgWithCallback(
		reqMsg: grpc.ProtobufMessage,
		onMessageFn: (respMsg: grpc.ProtobufMessage) => void,
	): void {
		console.log('Grpc send: ', reqMsg);
		this.client.sendWithCallback(reqMsg, onMessageFn);
	}

	public setOnMessageHandler(onMessageFn: (respMsg: grpc.ProtobufMessage) => void): void {
		this.client.onMessage(onMessageFn);
	}

	// eslint-disable-next-line class-methods-use-this
	public sendMsgUnary(
		service: grpc.UnaryMethodDefinition<ProtobufMessage, ProtobufMessage>,
		props: grpc.UnaryRpcOptions<ProtobufMessage, ProtobufMessage>,
	): void {
		grpc.unary(service, props);
	}
}
