import {
	ActionFromReducersMapObject,
	applyMiddleware,
	combineReducers,
	createStore,
	Reducer,
	Store,
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { sessionReducer, navOpenStateReducer } from '../system/reducers';
import { ApplicationState } from '../system/types';

const rootReducer = combineReducers<Reducer<ApplicationState, ActionFromReducersMapObject<never>>>({
	session: sessionReducer,
	nav: navOpenStateReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

function configureStore(): Store {
	const middlewares = [thunkMiddleware];
	const middleWareEnhancer = applyMiddleware(...middlewares);

	return createStore(rootReducer, composeWithDevTools(middleWareEnhancer));
}

const reduxStore: Store = configureStore();

export default reduxStore;
