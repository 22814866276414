export interface SessionState {
	loggedIn: boolean;
	sessionToken: string;
	userEmail: string;
	tokenExpiration: number;
}

export interface NavOpenState {
	isOpen: boolean;
}

export interface ApplicationState {
	session: SessionState;
	nav: NavOpenState;
}

export const UPDATE_SESSION = 'UPDATE_SESSION';
export const NAV_CLOSE = 'NAV_CLOSE';
export const NAV_TOGGLE = 'NAV_TOGGLE';

export interface UpdateSessionAction {
	type: typeof UPDATE_SESSION;
	payload: SessionState;
}

export interface NavCloseAction {
	type: typeof NAV_CLOSE;
}

export interface NavToggleAction {
	type: typeof NAV_TOGGLE;
}

export type SessionActionTypes = UpdateSessionAction;
export type NavActionTypes = NavCloseAction | NavToggleAction;
