import { Theme } from '@mui/material/styles';
import useMainTheme from './mainTheme';

export default function useTableFormTheme(): Theme {
	const theme: Theme = useMainTheme();

	return {
		...theme,
		components: {
			MuiPaper: {
				styleOverrides: {
					root: {
						margin: 'auto',
						marginLeft: theme.spacing(2),
						marginRight: theme.spacing(2),
						paddingTop: theme.spacing(1),
						paddingLeft: theme.spacing(4),
						paddingRight: theme.spacing(4),
						paddingBottom: theme.spacing(4),
						// [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
						// 	marginTop: theme.spacing(6),
						// 	marginBottom: theme.spacing(0),
						// 	padding: theme.spacing(3),
						// },
					},
				},
			},
		},
	};
}
