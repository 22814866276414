import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { StyleRules } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import NavDrawer from './NavDrawer';
import { toggleNavOpen } from '../redux/system/actions';

const useStyles = makeStyles(
	(theme: Theme): StyleRules =>
		createStyles({
			root: {
				display: 'flex',
				flexGrow: 1,
				color: 'white',
				background: 'black',
				secondary: 'white',
			},
			menuButton: {
				marginRight: theme.spacing(2),
				color: 'white',
				background: 'black',
				secondary: 'white',
			},
			title: {
				flexGrow: 1,
				marginRight: '56px',
			},
			group: {
				display: 'inline',
				verticalAlign: 'top',
				paddingRight: '2px',
			},
		}),
);

const NavBar = function NavBar(): React.ReactElement {
	const classes = useStyles();

	const dispatch = useDispatch();

	const toggleNavWrapper = useCallback(() => {
		dispatch(toggleNavOpen());
	}, [dispatch]);

	return (
		<div className={classes.root}>
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar>
					<IconButton
						edge="start"
						className={classes.menuButton}
						color="primary"
						aria-label="Menu"
						onClick={toggleNavWrapper}
						size="large"
					>
						<MenuIcon />
					</IconButton>
					<div className={classes.title}>
						<img
							className={classes.group}
							src={`${process.env.PUBLIC_URL}./images/gear.svg`}
							width={32}
							height={32}
							alt=""
						/>
						<Typography className={classes.group} variant="h6">
							AddRobots
						</Typography>
					</div>
				</Toolbar>
			</AppBar>
			<NavDrawer />
		</div>
	);
};

export default NavBar;
