import { createTheme, ThemeOptions, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';

export default function useMainTheme(options?: ThemeOptions): Theme {
	const theme: Theme = createTheme({
		...options,
		palette: {
			mode: useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light',
		},
	});

	return {
		...theme,
		components: {
			MuiAppBar: {
				styleOverrides: {
					root: {
						position: 'absolute',
						width: '100%',
						zIndex: 1400,
						background: 'black',
						secondary: 'white',
						minHeight: '48px',
					},
					colorPrimary: {
						backgroundColor: 'black',
					},
				},
			},
			MuiToolbar: {
				styleOverrides: {
					root: {
						backgroundColor: 'black',
						color: 'white',
					},
					regular: {
						minHeight: '48px',
						'@media (min-width: 600px)': {
							minHeight: '48px',
						},
					},
				},
			},
			// super bizarre, but this works and seems to be the way to override material-table-specific themes
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			MTableToolbar: {
				actions: {
					color: 'red',
				},
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						// marginTop: theme.spacing(3),
						// width: '80%',
						// overflowX: 'auto',
						// marginBottom: theme.spacing(2),
					},
				},
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						paddingTop: 4,
						paddingBottom: 4,
						paddingLeft: 8,
						paddingRight: 2,
					},
					head: {
						// backgroundColor: 'black',
						// color: 'white',
					},
				},
			},
			MuiFormLabel: {
				styleOverrides: {
					root: {
						fontSize: '14px',
					},
				},
			},
			MuiInputBase: {
				styleOverrides: {
					root: {
						fontSize: '14px',
					},
				},
			},
		},
	};
}
