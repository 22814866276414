import { Metadata } from '@addrobots/grpc-web/dist/typings/metadata';
import { grpc } from '@addrobots/grpc-web';
import { navigate } from '@reach/router';
import { Query, QueryResult } from '@material-table/core';
import GrpcClient from '../util/GrpcClient';
import { Location } from '../../generated/main/grpcweb/account_service_def_pb';
import {
	CreateRobotResp,
	Robot,
	RobotReq,
	RobotSearch,
	FindRobotResp,
	UpdateRobotResp,
	FindRobotListResp,
	DeleteRobotResp,
} from '../../generated/main/grpcweb/robot_service_def_pb';
import { RobotService } from '../../generated/main/grpcweb/robot_service_def_pb_service';
import { Header, RequestHeader } from '../../generated/main/grpcweb/common_pb';
import {
	Device,
	DeviceSearch,
	FindDeviceListResp,
	CreateDeviceResp,
	DeviceReq,
	UpdateDeviceResp,
	DeleteDeviceResp,
} from '../../generated/main/grpcweb/device_service_def_pb';
import { DeviceService } from '../../generated/main/grpcweb/device_service_def_pb_service';
import Config from '../util/Config';

const onHeadersFn = (headers: Metadata): void => {
	console.log('GrpcClient onHeaders: ', headers);
};

export interface RobotRowData {
	robot: Robot;
	robotId: string;
	robotType: number;
}

export function createRobot(
	robotId: string,
	description: string,
	deviceToken: string,
	locationName: string,
	sessionToken: string,
): Promise<void> {
	return new Promise((resolve, reject) => {
		const transactionId = '12345678';

		const header = new Header();
		header.setMsgTransactionUuid(transactionId);
		const reqHeader = new RequestHeader();
		reqHeader.setHeader(header);
		reqHeader.setMsgAuthToken(sessionToken);

		const robot: Robot = new Robot();
		robot.setId(robotId);
		robot.setDescription(description);
		robot.setMsgDestAddress(deviceToken);

		const location: Location = new Location();
		location.setName(locationName);

		const robotReq: RobotReq = new RobotReq();
		robotReq.setReqHeader(reqHeader);
		robotReq.setRobot(robot);

		const grpcClient: GrpcClient = new GrpcClient(onHeadersFn);
		grpcClient.sendMsgUnary(RobotService.CreateRobot, {
			host: Config.AppServerUrl,
			transport: grpc.CrossBrowserHttpTransport({ withCredentials: true }),
			metadata: new grpc.Metadata({ 'x-auth-token': sessionToken }),
			request: robotReq,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			onEnd: ({ status, statusMessage, headers, message, trailers }) => {
				if (message == null) {
					reject();
				} else {
					const createRobotResp: CreateRobotResp = message as CreateRobotResp;

					switch (createRobotResp.getResponseCode()) {
						case CreateRobotResp.ResponseCode.SUCCESS:
							navigate('/robot/inventory');
							break;
						case CreateRobotResp.ResponseCode.ALREADY_EXISTS:
							break;
						default:
					}
					resolve();
				}
			},
		});
	});
}

export function updateRobot(
	robotId: string,
	description: string,
	deviceToken: string,
	locationName: string,
	sessionToken: string,
): Promise<void> {
	return new Promise((resolve, reject) => {
		const transactionId = '12345678';

		const header = new Header();
		header.setMsgTransactionUuid(transactionId);
		const reqHeader = new RequestHeader();
		reqHeader.setHeader(header);
		reqHeader.setMsgAuthToken(sessionToken);

		const robot: Robot = new Robot();
		robot.setId(robotId);
		robot.setDescription(description);
		robot.setMsgDestAddress(deviceToken);

		const location: Location = new Location();
		location.setName(locationName);

		const robotReq: RobotReq = new RobotReq();
		robotReq.setReqHeader(reqHeader);
		robotReq.setRobot(robot);

		const grpcClient: GrpcClient = new GrpcClient(onHeadersFn);
		grpcClient.sendMsgUnary(RobotService.UpdateRobot, {
			host: Config.AppServerUrl,
			transport: grpc.CrossBrowserHttpTransport({ withCredentials: true }),
			metadata: new grpc.Metadata({ 'x-auth-token': sessionToken }),
			request: robotReq,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			onEnd: ({ status, statusMessage, headers, message, trailers }) => {
				if (message == null) {
					reject();
				} else {
					const updateRobotResp: UpdateRobotResp = message as UpdateRobotResp;

					switch (updateRobotResp.getResponseCode()) {
						case UpdateRobotResp.ResponseCode.SUCCESS:
							navigate('/robot/inventory');
							break;
						case UpdateRobotResp.ResponseCode.PERMISSION_DENIED:
							console.log(`Update robot ${robotId} permission denied`);
							break;
						default:
					}
					resolve();
				}
			},
		});
	});
}

export function deleteRobot(robotId: string, sessionToken: string): Promise<void> {
	return new Promise((resolve, reject) => {
		const transactionId = '12345678';

		const header = new Header();
		header.setMsgTransactionUuid(transactionId);
		const reqHeader = new RequestHeader();
		reqHeader.setHeader(header);
		reqHeader.setMsgAuthToken(sessionToken);

		const robot: Robot = new Robot();
		robot.setId(robotId);

		const robotReq: RobotReq = new RobotReq();
		robotReq.setReqHeader(reqHeader);
		robotReq.setRobot(robot);

		const grpcClient: GrpcClient = new GrpcClient(onHeadersFn);
		grpcClient.sendMsgUnary(RobotService.DeleteRobot, {
			host: Config.AppServerUrl,
			transport: grpc.CrossBrowserHttpTransport({ withCredentials: true }),
			metadata: new grpc.Metadata({ 'x-auth-token': sessionToken }),
			request: robotReq,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			onEnd: ({ status, statusMessage, headers, message, trailers }) => {
				if (message == null) {
					reject();
				} else {
					const deleteRobotResp: DeleteRobotResp = message as DeleteRobotResp;

					switch (deleteRobotResp.getResponseCode()) {
						default:
					}
					resolve();
				}
			},
		});
	});
}

export function findRobot(robotId: string, sessionToken: string): Promise<Robot> {
	return new Promise((resolve, reject) => {
		const transactionId = '12345678';

		const header = new Header();
		header.setMsgTransactionUuid(transactionId);
		const reqHeader = new RequestHeader();
		reqHeader.setHeader(header);
		reqHeader.setMsgAuthToken(sessionToken);

		const robotSearch: RobotSearch = new RobotSearch();
		robotSearch.setReqHeader(reqHeader);
		robotSearch.setId(robotId);

		const grpcClient: GrpcClient = new GrpcClient(onHeadersFn);
		grpcClient.sendMsgUnary(RobotService.FindRobot, {
			host: Config.AppServerUrl,
			transport: grpc.CrossBrowserHttpTransport({ withCredentials: true }),
			metadata: new grpc.Metadata({ 'x-auth-token': sessionToken }),
			request: robotSearch,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			onEnd: ({ status, statusMessage, headers, message, trailers }) => {
				if (message == null) {
					reject();
				} else {
					const findRobotResp: FindRobotResp = message as FindRobotResp;

					if (findRobotResp.hasRobot()) {
						console.log(findRobotResp);
						resolve(findRobotResp.getRobot() as Robot);
					} else {
						reject();
					}
				}
			},
		});
	});
}

export function findRobots(
	query: Query<RobotRowData>,
	sessionToken: string,
): Promise<QueryResult<RobotRowData>> {
	return new Promise<QueryResult<RobotRowData>>((resolve, reject) => {
		const header = new Header();
		header.setMsgTransactionUuid('12345678');
		const reqHeader = new RequestHeader();
		reqHeader.setHeader(header);
		reqHeader.setMsgAuthToken(sessionToken);

		const robotSearch: RobotSearch = new RobotSearch();
		robotSearch.setReqHeader(reqHeader);

		const grpcClient = new GrpcClient(onHeadersFn);
		grpcClient.sendMsgUnary(RobotService.FindRobots, {
			host: Config.AppServerUrl,
			transport: grpc.CrossBrowserHttpTransport({ withCredentials: true }),
			metadata: new grpc.Metadata({ 'x-auth-token': sessionToken }),
			request: robotSearch,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			onEnd: ({ status, statusMessage, headers, message, trailers }) => {
				const robotResp: FindRobotListResp = message as FindRobotListResp;
				if (robotResp !== null && robotResp.getRobotList() !== null) {
					const rowData: RobotRowData[] = [];
					robotResp.getRobotList().forEach(function eachRobot(robot: Robot) {
						rowData.push({ robot, robotId: robot.getId(), robotType: 1 });
					});
					resolve({
						data: rowData,
						page: 0,
						totalCount: 1,
					});
				} else {
					reject(status);
				}
			},
		});
	});
}

export interface DeviceRowData {
	device: Device;
	guid: string;
	type: string;
	description: string;
}

export function createDevice(
	deviceId: string,
	robotId: string,
	description: string,
	type: string,
	sessionToken: string,
): Promise<void> {
	return new Promise((resolve, reject) => {
		const transactionId = '12345678';

		const header = new Header();
		header.setMsgTransactionUuid(transactionId);
		const reqHeader = new RequestHeader();
		reqHeader.setHeader(header);
		reqHeader.setMsgAuthToken(sessionToken);

		const device: Device = new Device();
		device.setId(deviceId);
		device.setParentRobotId(robotId);
		device.setDescription(description);
		device.setType(type);

		const deviceReq: DeviceReq = new DeviceReq();
		deviceReq.setReqHeader(reqHeader);
		deviceReq.setDevice(device);

		const grpcClient: GrpcClient = new GrpcClient(onHeadersFn);
		grpcClient.sendMsgUnary(DeviceService.CreateDevice, {
			host: Config.AppServerUrl,
			transport: grpc.CrossBrowserHttpTransport({ withCredentials: true }),
			metadata: new grpc.Metadata({ 'x-auth-token': sessionToken }),
			request: deviceReq,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			onEnd: ({ status, statusMessage, headers, message, trailers }) => {
				if (message == null) {
					reject();
				} else {
					const createDeviceResp: CreateDeviceResp = message as CreateDeviceResp;

					switch (createDeviceResp.getResponseCode()) {
						case CreateDeviceResp.ResponseCode.SUCCESS:
							navigate('/robot/inventory');
							break;
						case CreateDeviceResp.ResponseCode.ALREADY_EXISTS:
							break;
						default:
					}
					resolve();
				}
			},
		});
	});
}

export function updateDevice(
	deviceId: string,
	robotId: string,
	description: string,
	type: string,
	sessionToken: string,
): Promise<void> {
	return new Promise((resolve, reject) => {
		const transactionId = '12345678';

		const header = new Header();
		header.setMsgTransactionUuid(transactionId);
		const reqHeader = new RequestHeader();
		reqHeader.setHeader(header);
		reqHeader.setMsgAuthToken(sessionToken);

		const device: Device = new Device();
		device.setId(deviceId);
		device.setParentRobotId(robotId);
		device.setDescription(description);
		device.setType(type);

		const deviceReq: DeviceReq = new DeviceReq();
		deviceReq.setReqHeader(reqHeader);
		deviceReq.setDevice(device);

		const grpcClient: GrpcClient = new GrpcClient(onHeadersFn);
		grpcClient.sendMsgUnary(DeviceService.UpdateDevice, {
			host: Config.AppServerUrl,
			transport: grpc.CrossBrowserHttpTransport({ withCredentials: true }),
			metadata: new grpc.Metadata({ 'x-auth-token': sessionToken }),
			request: deviceReq,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			onEnd: ({ status, statusMessage, headers, message, trailers }) => {
				if (message == null) {
					reject();
				} else {
					const updateDeviceResp: UpdateDeviceResp = message as UpdateDeviceResp;

					switch (updateDeviceResp.getResponseCode()) {
						default:
					}
					resolve();
				}
			},
		});
	});
}

export function deleteDevice(deviceId: string, sessionToken: string): Promise<void> {
	return new Promise((resolve, reject) => {
		const transactionId = '12345678';

		const header = new Header();
		header.setMsgTransactionUuid(transactionId);
		const reqHeader = new RequestHeader();
		reqHeader.setHeader(header);
		reqHeader.setMsgAuthToken(sessionToken);

		const device: Device = new Device();
		device.setId(deviceId);

		const deviceReq: DeviceReq = new DeviceReq();
		deviceReq.setReqHeader(reqHeader);
		deviceReq.setDevice(device);

		const grpcClient: GrpcClient = new GrpcClient(onHeadersFn);
		grpcClient.sendMsgUnary(DeviceService.DeleteDevice, {
			host: Config.AppServerUrl,
			transport: grpc.CrossBrowserHttpTransport({ withCredentials: true }),
			metadata: new grpc.Metadata({ 'x-auth-token': sessionToken }),
			request: deviceReq,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			onEnd: ({ status, statusMessage, headers, message, trailers }) => {
				if (message == null) {
					reject();
				} else {
					const deleteDeviceResp: DeleteDeviceResp = message as DeleteDeviceResp;

					switch (deleteDeviceResp.getResponseCode()) {
						default:
					}
					resolve();
				}
			},
		});
	});
}

export function findDevices(
	query: Query<DeviceRowData>,
	sessionToken: string,
): Promise<QueryResult<DeviceRowData>> {
	return new Promise<QueryResult<DeviceRowData>>((resolve, reject) => {
		const header = new Header();
		header.setMsgTransactionUuid('12345678');
		const reqHeader = new RequestHeader();
		reqHeader.setHeader(header);
		reqHeader.setMsgAuthToken(sessionToken);

		const deviceSearch: DeviceSearch = new DeviceSearch();
		deviceSearch.setReqHeader(reqHeader);

		const grpcClient = new GrpcClient(onHeadersFn);
		grpcClient.sendMsgUnary(DeviceService.FindDevices, {
			host: Config.AppServerUrl,
			transport: grpc.CrossBrowserHttpTransport({ withCredentials: true }),
			metadata: new grpc.Metadata({ 'x-auth-token': sessionToken }),
			request: deviceSearch,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			onEnd: ({ status, statusMessage, headers, message, trailers }) => {
				const deviceResp: FindDeviceListResp = message as FindDeviceListResp;

				if (deviceResp !== null && deviceResp.getDeviceList() !== null) {
					const rowData: DeviceRowData[] = [];
					deviceResp.getDeviceList().forEach(function eachDevice(device: Device) {
						rowData.push({
							device,
							guid: device.getId(),
							type: device.getType(),
							description: device.getDescription(),
						});
					});
					resolve({
						data: rowData,
						page: 0,
						totalCount: 1,
					});
				} else {
					reject(status);
				}
			},
		});
	});
}
