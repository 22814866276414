import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import { Link } from '@reach/router';
import Drawer from '@mui/material/Drawer';
import { CloudOffSharp, CloudQueueSharp, Home, Info, List as ListIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Theme } from '@mui/material/styles';
import { StyleRules } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { setNavClosed } from '../redux/system/actions';
import { ApplicationState } from '../redux/system/types';
import GoogleAuth from '../util/GoogleAuth';

const drawerWidth = 240;

const useStyles = makeStyles(
	(theme: Theme): StyleRules =>
		createStyles({
			drawer: {
				width: drawerWidth,
				flexShrink: 0,
			},
			drawerPaper: {
				width: drawerWidth,
			},
			toolbar: theme.mixins.toolbar,
		}),
);

const NavDrawer = function NavDrawer(): React.ReactElement {
	const classes = useStyles();

	const [sessionExpireTime, setSessionExpireTime] = useState(Date.now());

	const isLoggedIn = useSelector((state: ApplicationState) => state.session.loggedIn);
	const isNavOpen = useSelector((state: ApplicationState) => state.nav.isOpen);
	const dispatch = useDispatch();

	const closeNavWrapper = useCallback(() => {
		dispatch(setNavClosed());
	}, [dispatch]);

	useEffect(() => {
		GoogleAuth.Instance.restorePreviousLogin();
		const interval = setInterval(() => setSessionExpireTime(sessionExpireTime), 1000);
		return (): void => {
			GoogleAuth.Instance.logout();
			clearInterval(interval);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<Drawer
				className={classes.drawer}
				classes={{ paper: classes.drawerPaper }}
				open={isNavOpen}
				onClick={closeNavWrapper}
			>
				<div className={classes.toolbar} />
				<List>
					<ListItem button key="Home" component={Link} to="/">
						<ListItemIcon>
							<Home />
						</ListItemIcon>
						<ListItemText primary="Home" />
					</ListItem>
				</List>
				<Divider />
				{!isLoggedIn ? (
					<List>
						<ListItem
							button
							key="Login"
							onClick={GoogleAuth.Instance.login}
							disabled={isLoggedIn}
						>
							<ListItemIcon>
								<CloudQueueSharp />
							</ListItemIcon>
							<ListItemText primary="Login" />
						</ListItem>
					</List>
				) : (
					<div />
				)}
				{isLoggedIn ? (
					<List>
						<ListItem
							button
							key="Logout"
							onClick={GoogleAuth.Instance.logout}
							disabled={!isLoggedIn}
						>
							<ListItemIcon>
								<CloudOffSharp />
							</ListItemIcon>
							<ListItemText primary="Logout" />
						</ListItem>
					</List>
				) : (
					<div />
				)}
				{isLoggedIn ? (
					<List>
						<ListItem
							button
							key="Robot Inventory"
							component={Link}
							to="/robot/inventory"
						>
							<ListItemIcon>
								<ListIcon />
							</ListItemIcon>
							<ListItemText primary="Robot Inventory" />
						</ListItem>
					</List>
				) : (
					<div />
				)}
				<List>
					<ListItem button key="Info" component={Link} to="/info">
						<ListItemIcon>
							<Info />
						</ListItemIcon>
						<ListItemText primary="Info" />
					</ListItem>
				</List>
			</Drawer>
		</div>
	);
};

export default NavDrawer;
