// source: robot_service_def.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.com.addrobots.grpc.CreateRobotResp', null, global);
goog.exportSymbol('proto.com.addrobots.grpc.CreateRobotResp.ResponseCode', null, global);
goog.exportSymbol('proto.com.addrobots.grpc.DeleteRobotResp', null, global);
goog.exportSymbol('proto.com.addrobots.grpc.DeleteRobotResp.ResponseCode', null, global);
goog.exportSymbol('proto.com.addrobots.grpc.FindRobotListResp', null, global);
goog.exportSymbol('proto.com.addrobots.grpc.FindRobotResp', null, global);
goog.exportSymbol('proto.com.addrobots.grpc.Robot', null, global);
goog.exportSymbol('proto.com.addrobots.grpc.RobotReq', null, global);
goog.exportSymbol('proto.com.addrobots.grpc.RobotSearch', null, global);
goog.exportSymbol('proto.com.addrobots.grpc.UpdateRobotResp', null, global);
goog.exportSymbol('proto.com.addrobots.grpc.UpdateRobotResp.ResponseCode', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.addrobots.grpc.Robot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.addrobots.grpc.Robot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.addrobots.grpc.Robot.displayName = 'proto.com.addrobots.grpc.Robot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.addrobots.grpc.RobotReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.addrobots.grpc.RobotReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.addrobots.grpc.RobotReq.displayName = 'proto.com.addrobots.grpc.RobotReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.addrobots.grpc.RobotSearch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.addrobots.grpc.RobotSearch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.addrobots.grpc.RobotSearch.displayName = 'proto.com.addrobots.grpc.RobotSearch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.addrobots.grpc.CreateRobotResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.addrobots.grpc.CreateRobotResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.addrobots.grpc.CreateRobotResp.displayName = 'proto.com.addrobots.grpc.CreateRobotResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.addrobots.grpc.UpdateRobotResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.addrobots.grpc.UpdateRobotResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.addrobots.grpc.UpdateRobotResp.displayName = 'proto.com.addrobots.grpc.UpdateRobotResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.addrobots.grpc.DeleteRobotResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.addrobots.grpc.DeleteRobotResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.addrobots.grpc.DeleteRobotResp.displayName = 'proto.com.addrobots.grpc.DeleteRobotResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.addrobots.grpc.FindRobotResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.addrobots.grpc.FindRobotResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.addrobots.grpc.FindRobotResp.displayName = 'proto.com.addrobots.grpc.FindRobotResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.addrobots.grpc.FindRobotListResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.addrobots.grpc.FindRobotListResp.repeatedFields_, null);
};
goog.inherits(proto.com.addrobots.grpc.FindRobotListResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.addrobots.grpc.FindRobotListResp.displayName = 'proto.com.addrobots.grpc.FindRobotListResp';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.addrobots.grpc.Robot.prototype.toObject = function(opt_includeInstance) {
  return proto.com.addrobots.grpc.Robot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.addrobots.grpc.Robot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.Robot.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    msgDestAddress: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.addrobots.grpc.Robot}
 */
proto.com.addrobots.grpc.Robot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.addrobots.grpc.Robot;
  return proto.com.addrobots.grpc.Robot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.addrobots.grpc.Robot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.addrobots.grpc.Robot}
 */
proto.com.addrobots.grpc.Robot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMsgDestAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.addrobots.grpc.Robot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.addrobots.grpc.Robot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.addrobots.grpc.Robot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.Robot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMsgDestAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.addrobots.grpc.Robot.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.addrobots.grpc.Robot} returns this
 */
proto.com.addrobots.grpc.Robot.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.com.addrobots.grpc.Robot.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.addrobots.grpc.Robot} returns this
 */
proto.com.addrobots.grpc.Robot.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string msg_dest_address = 3;
 * @return {string}
 */
proto.com.addrobots.grpc.Robot.prototype.getMsgDestAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.addrobots.grpc.Robot} returns this
 */
proto.com.addrobots.grpc.Robot.prototype.setMsgDestAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.addrobots.grpc.RobotReq.prototype.toObject = function(opt_includeInstance) {
  return proto.com.addrobots.grpc.RobotReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.addrobots.grpc.RobotReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.RobotReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    reqHeader: (f = msg.getReqHeader()) && common_pb.RequestHeader.toObject(includeInstance, f),
    robot: (f = msg.getRobot()) && proto.com.addrobots.grpc.Robot.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.addrobots.grpc.RobotReq}
 */
proto.com.addrobots.grpc.RobotReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.addrobots.grpc.RobotReq;
  return proto.com.addrobots.grpc.RobotReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.addrobots.grpc.RobotReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.addrobots.grpc.RobotReq}
 */
proto.com.addrobots.grpc.RobotReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.RequestHeader;
      reader.readMessage(value,common_pb.RequestHeader.deserializeBinaryFromReader);
      msg.setReqHeader(value);
      break;
    case 2:
      var value = new proto.com.addrobots.grpc.Robot;
      reader.readMessage(value,proto.com.addrobots.grpc.Robot.deserializeBinaryFromReader);
      msg.setRobot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.addrobots.grpc.RobotReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.addrobots.grpc.RobotReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.addrobots.grpc.RobotReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.RobotReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReqHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getRobot();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.addrobots.grpc.Robot.serializeBinaryToWriter
    );
  }
};


/**
 * optional com.addrobots.RequestHeader req_header = 1;
 * @return {?proto.com.addrobots.RequestHeader}
 */
proto.com.addrobots.grpc.RobotReq.prototype.getReqHeader = function() {
  return /** @type{?proto.com.addrobots.RequestHeader} */ (
    jspb.Message.getWrapperField(this, common_pb.RequestHeader, 1));
};


/**
 * @param {?proto.com.addrobots.RequestHeader|undefined} value
 * @return {!proto.com.addrobots.grpc.RobotReq} returns this
*/
proto.com.addrobots.grpc.RobotReq.prototype.setReqHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.RobotReq} returns this
 */
proto.com.addrobots.grpc.RobotReq.prototype.clearReqHeader = function() {
  return this.setReqHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.RobotReq.prototype.hasReqHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Robot robot = 2;
 * @return {?proto.com.addrobots.grpc.Robot}
 */
proto.com.addrobots.grpc.RobotReq.prototype.getRobot = function() {
  return /** @type{?proto.com.addrobots.grpc.Robot} */ (
    jspb.Message.getWrapperField(this, proto.com.addrobots.grpc.Robot, 2));
};


/**
 * @param {?proto.com.addrobots.grpc.Robot|undefined} value
 * @return {!proto.com.addrobots.grpc.RobotReq} returns this
*/
proto.com.addrobots.grpc.RobotReq.prototype.setRobot = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.RobotReq} returns this
 */
proto.com.addrobots.grpc.RobotReq.prototype.clearRobot = function() {
  return this.setRobot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.RobotReq.prototype.hasRobot = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.addrobots.grpc.RobotSearch.prototype.toObject = function(opt_includeInstance) {
  return proto.com.addrobots.grpc.RobotSearch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.addrobots.grpc.RobotSearch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.RobotSearch.toObject = function(includeInstance, msg) {
  var f, obj = {
    reqHeader: (f = msg.getReqHeader()) && common_pb.RequestHeader.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.addrobots.grpc.RobotSearch}
 */
proto.com.addrobots.grpc.RobotSearch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.addrobots.grpc.RobotSearch;
  return proto.com.addrobots.grpc.RobotSearch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.addrobots.grpc.RobotSearch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.addrobots.grpc.RobotSearch}
 */
proto.com.addrobots.grpc.RobotSearch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.RequestHeader;
      reader.readMessage(value,common_pb.RequestHeader.deserializeBinaryFromReader);
      msg.setReqHeader(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.addrobots.grpc.RobotSearch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.addrobots.grpc.RobotSearch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.addrobots.grpc.RobotSearch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.RobotSearch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReqHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional com.addrobots.RequestHeader req_header = 1;
 * @return {?proto.com.addrobots.RequestHeader}
 */
proto.com.addrobots.grpc.RobotSearch.prototype.getReqHeader = function() {
  return /** @type{?proto.com.addrobots.RequestHeader} */ (
    jspb.Message.getWrapperField(this, common_pb.RequestHeader, 1));
};


/**
 * @param {?proto.com.addrobots.RequestHeader|undefined} value
 * @return {!proto.com.addrobots.grpc.RobotSearch} returns this
*/
proto.com.addrobots.grpc.RobotSearch.prototype.setReqHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.RobotSearch} returns this
 */
proto.com.addrobots.grpc.RobotSearch.prototype.clearReqHeader = function() {
  return this.setReqHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.RobotSearch.prototype.hasReqHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.com.addrobots.grpc.RobotSearch.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.addrobots.grpc.RobotSearch} returns this
 */
proto.com.addrobots.grpc.RobotSearch.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.addrobots.grpc.CreateRobotResp.prototype.toObject = function(opt_includeInstance) {
  return proto.com.addrobots.grpc.CreateRobotResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.addrobots.grpc.CreateRobotResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.CreateRobotResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    respHeader: (f = msg.getRespHeader()) && common_pb.ResponseHeader.toObject(includeInstance, f),
    responseCode: jspb.Message.getFieldWithDefault(msg, 2, 0),
    robot: (f = msg.getRobot()) && proto.com.addrobots.grpc.Robot.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.addrobots.grpc.CreateRobotResp}
 */
proto.com.addrobots.grpc.CreateRobotResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.addrobots.grpc.CreateRobotResp;
  return proto.com.addrobots.grpc.CreateRobotResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.addrobots.grpc.CreateRobotResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.addrobots.grpc.CreateRobotResp}
 */
proto.com.addrobots.grpc.CreateRobotResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ResponseHeader;
      reader.readMessage(value,common_pb.ResponseHeader.deserializeBinaryFromReader);
      msg.setRespHeader(value);
      break;
    case 2:
      var value = /** @type {!proto.com.addrobots.grpc.CreateRobotResp.ResponseCode} */ (reader.readEnum());
      msg.setResponseCode(value);
      break;
    case 3:
      var value = new proto.com.addrobots.grpc.Robot;
      reader.readMessage(value,proto.com.addrobots.grpc.Robot.deserializeBinaryFromReader);
      msg.setRobot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.addrobots.grpc.CreateRobotResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.addrobots.grpc.CreateRobotResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.addrobots.grpc.CreateRobotResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.CreateRobotResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRespHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getResponseCode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getRobot();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.addrobots.grpc.Robot.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.com.addrobots.grpc.CreateRobotResp.ResponseCode = {
  UNKNOWN: 0,
  SUCCESS: 1,
  ALREADY_EXISTS: 2,
  PERMISSION_DENIED: 3
};

/**
 * optional com.addrobots.ResponseHeader resp_header = 1;
 * @return {?proto.com.addrobots.ResponseHeader}
 */
proto.com.addrobots.grpc.CreateRobotResp.prototype.getRespHeader = function() {
  return /** @type{?proto.com.addrobots.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, common_pb.ResponseHeader, 1));
};


/**
 * @param {?proto.com.addrobots.ResponseHeader|undefined} value
 * @return {!proto.com.addrobots.grpc.CreateRobotResp} returns this
*/
proto.com.addrobots.grpc.CreateRobotResp.prototype.setRespHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.CreateRobotResp} returns this
 */
proto.com.addrobots.grpc.CreateRobotResp.prototype.clearRespHeader = function() {
  return this.setRespHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.CreateRobotResp.prototype.hasRespHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResponseCode response_code = 2;
 * @return {!proto.com.addrobots.grpc.CreateRobotResp.ResponseCode}
 */
proto.com.addrobots.grpc.CreateRobotResp.prototype.getResponseCode = function() {
  return /** @type {!proto.com.addrobots.grpc.CreateRobotResp.ResponseCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.addrobots.grpc.CreateRobotResp.ResponseCode} value
 * @return {!proto.com.addrobots.grpc.CreateRobotResp} returns this
 */
proto.com.addrobots.grpc.CreateRobotResp.prototype.setResponseCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Robot robot = 3;
 * @return {?proto.com.addrobots.grpc.Robot}
 */
proto.com.addrobots.grpc.CreateRobotResp.prototype.getRobot = function() {
  return /** @type{?proto.com.addrobots.grpc.Robot} */ (
    jspb.Message.getWrapperField(this, proto.com.addrobots.grpc.Robot, 3));
};


/**
 * @param {?proto.com.addrobots.grpc.Robot|undefined} value
 * @return {!proto.com.addrobots.grpc.CreateRobotResp} returns this
*/
proto.com.addrobots.grpc.CreateRobotResp.prototype.setRobot = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.CreateRobotResp} returns this
 */
proto.com.addrobots.grpc.CreateRobotResp.prototype.clearRobot = function() {
  return this.setRobot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.CreateRobotResp.prototype.hasRobot = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.addrobots.grpc.UpdateRobotResp.prototype.toObject = function(opt_includeInstance) {
  return proto.com.addrobots.grpc.UpdateRobotResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.addrobots.grpc.UpdateRobotResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.UpdateRobotResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    respHeader: (f = msg.getRespHeader()) && common_pb.ResponseHeader.toObject(includeInstance, f),
    responseCode: jspb.Message.getFieldWithDefault(msg, 2, 0),
    robot: (f = msg.getRobot()) && proto.com.addrobots.grpc.Robot.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.addrobots.grpc.UpdateRobotResp}
 */
proto.com.addrobots.grpc.UpdateRobotResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.addrobots.grpc.UpdateRobotResp;
  return proto.com.addrobots.grpc.UpdateRobotResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.addrobots.grpc.UpdateRobotResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.addrobots.grpc.UpdateRobotResp}
 */
proto.com.addrobots.grpc.UpdateRobotResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ResponseHeader;
      reader.readMessage(value,common_pb.ResponseHeader.deserializeBinaryFromReader);
      msg.setRespHeader(value);
      break;
    case 2:
      var value = /** @type {!proto.com.addrobots.grpc.UpdateRobotResp.ResponseCode} */ (reader.readEnum());
      msg.setResponseCode(value);
      break;
    case 3:
      var value = new proto.com.addrobots.grpc.Robot;
      reader.readMessage(value,proto.com.addrobots.grpc.Robot.deserializeBinaryFromReader);
      msg.setRobot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.addrobots.grpc.UpdateRobotResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.addrobots.grpc.UpdateRobotResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.addrobots.grpc.UpdateRobotResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.UpdateRobotResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRespHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getResponseCode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getRobot();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.addrobots.grpc.Robot.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.com.addrobots.grpc.UpdateRobotResp.ResponseCode = {
  UNKNOWN: 0,
  SUCCESS: 1,
  PERMISSION_DENIED: 2
};

/**
 * optional com.addrobots.ResponseHeader resp_header = 1;
 * @return {?proto.com.addrobots.ResponseHeader}
 */
proto.com.addrobots.grpc.UpdateRobotResp.prototype.getRespHeader = function() {
  return /** @type{?proto.com.addrobots.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, common_pb.ResponseHeader, 1));
};


/**
 * @param {?proto.com.addrobots.ResponseHeader|undefined} value
 * @return {!proto.com.addrobots.grpc.UpdateRobotResp} returns this
*/
proto.com.addrobots.grpc.UpdateRobotResp.prototype.setRespHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.UpdateRobotResp} returns this
 */
proto.com.addrobots.grpc.UpdateRobotResp.prototype.clearRespHeader = function() {
  return this.setRespHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.UpdateRobotResp.prototype.hasRespHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResponseCode response_code = 2;
 * @return {!proto.com.addrobots.grpc.UpdateRobotResp.ResponseCode}
 */
proto.com.addrobots.grpc.UpdateRobotResp.prototype.getResponseCode = function() {
  return /** @type {!proto.com.addrobots.grpc.UpdateRobotResp.ResponseCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.addrobots.grpc.UpdateRobotResp.ResponseCode} value
 * @return {!proto.com.addrobots.grpc.UpdateRobotResp} returns this
 */
proto.com.addrobots.grpc.UpdateRobotResp.prototype.setResponseCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Robot robot = 3;
 * @return {?proto.com.addrobots.grpc.Robot}
 */
proto.com.addrobots.grpc.UpdateRobotResp.prototype.getRobot = function() {
  return /** @type{?proto.com.addrobots.grpc.Robot} */ (
    jspb.Message.getWrapperField(this, proto.com.addrobots.grpc.Robot, 3));
};


/**
 * @param {?proto.com.addrobots.grpc.Robot|undefined} value
 * @return {!proto.com.addrobots.grpc.UpdateRobotResp} returns this
*/
proto.com.addrobots.grpc.UpdateRobotResp.prototype.setRobot = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.UpdateRobotResp} returns this
 */
proto.com.addrobots.grpc.UpdateRobotResp.prototype.clearRobot = function() {
  return this.setRobot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.UpdateRobotResp.prototype.hasRobot = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.addrobots.grpc.DeleteRobotResp.prototype.toObject = function(opt_includeInstance) {
  return proto.com.addrobots.grpc.DeleteRobotResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.addrobots.grpc.DeleteRobotResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.DeleteRobotResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    respHeader: (f = msg.getRespHeader()) && common_pb.ResponseHeader.toObject(includeInstance, f),
    responseCode: jspb.Message.getFieldWithDefault(msg, 2, 0),
    robot: (f = msg.getRobot()) && proto.com.addrobots.grpc.Robot.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.addrobots.grpc.DeleteRobotResp}
 */
proto.com.addrobots.grpc.DeleteRobotResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.addrobots.grpc.DeleteRobotResp;
  return proto.com.addrobots.grpc.DeleteRobotResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.addrobots.grpc.DeleteRobotResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.addrobots.grpc.DeleteRobotResp}
 */
proto.com.addrobots.grpc.DeleteRobotResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ResponseHeader;
      reader.readMessage(value,common_pb.ResponseHeader.deserializeBinaryFromReader);
      msg.setRespHeader(value);
      break;
    case 2:
      var value = /** @type {!proto.com.addrobots.grpc.DeleteRobotResp.ResponseCode} */ (reader.readEnum());
      msg.setResponseCode(value);
      break;
    case 3:
      var value = new proto.com.addrobots.grpc.Robot;
      reader.readMessage(value,proto.com.addrobots.grpc.Robot.deserializeBinaryFromReader);
      msg.setRobot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.addrobots.grpc.DeleteRobotResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.addrobots.grpc.DeleteRobotResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.addrobots.grpc.DeleteRobotResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.DeleteRobotResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRespHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getResponseCode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getRobot();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.addrobots.grpc.Robot.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.com.addrobots.grpc.DeleteRobotResp.ResponseCode = {
  UNKNOWN: 0,
  SUCCESS: 1,
  FAILED: 2
};

/**
 * optional com.addrobots.ResponseHeader resp_header = 1;
 * @return {?proto.com.addrobots.ResponseHeader}
 */
proto.com.addrobots.grpc.DeleteRobotResp.prototype.getRespHeader = function() {
  return /** @type{?proto.com.addrobots.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, common_pb.ResponseHeader, 1));
};


/**
 * @param {?proto.com.addrobots.ResponseHeader|undefined} value
 * @return {!proto.com.addrobots.grpc.DeleteRobotResp} returns this
*/
proto.com.addrobots.grpc.DeleteRobotResp.prototype.setRespHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.DeleteRobotResp} returns this
 */
proto.com.addrobots.grpc.DeleteRobotResp.prototype.clearRespHeader = function() {
  return this.setRespHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.DeleteRobotResp.prototype.hasRespHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResponseCode response_code = 2;
 * @return {!proto.com.addrobots.grpc.DeleteRobotResp.ResponseCode}
 */
proto.com.addrobots.grpc.DeleteRobotResp.prototype.getResponseCode = function() {
  return /** @type {!proto.com.addrobots.grpc.DeleteRobotResp.ResponseCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.addrobots.grpc.DeleteRobotResp.ResponseCode} value
 * @return {!proto.com.addrobots.grpc.DeleteRobotResp} returns this
 */
proto.com.addrobots.grpc.DeleteRobotResp.prototype.setResponseCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Robot robot = 3;
 * @return {?proto.com.addrobots.grpc.Robot}
 */
proto.com.addrobots.grpc.DeleteRobotResp.prototype.getRobot = function() {
  return /** @type{?proto.com.addrobots.grpc.Robot} */ (
    jspb.Message.getWrapperField(this, proto.com.addrobots.grpc.Robot, 3));
};


/**
 * @param {?proto.com.addrobots.grpc.Robot|undefined} value
 * @return {!proto.com.addrobots.grpc.DeleteRobotResp} returns this
*/
proto.com.addrobots.grpc.DeleteRobotResp.prototype.setRobot = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.DeleteRobotResp} returns this
 */
proto.com.addrobots.grpc.DeleteRobotResp.prototype.clearRobot = function() {
  return this.setRobot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.DeleteRobotResp.prototype.hasRobot = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.addrobots.grpc.FindRobotResp.prototype.toObject = function(opt_includeInstance) {
  return proto.com.addrobots.grpc.FindRobotResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.addrobots.grpc.FindRobotResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.FindRobotResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    respHeader: (f = msg.getRespHeader()) && common_pb.ResponseHeader.toObject(includeInstance, f),
    robot: (f = msg.getRobot()) && proto.com.addrobots.grpc.Robot.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.addrobots.grpc.FindRobotResp}
 */
proto.com.addrobots.grpc.FindRobotResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.addrobots.grpc.FindRobotResp;
  return proto.com.addrobots.grpc.FindRobotResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.addrobots.grpc.FindRobotResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.addrobots.grpc.FindRobotResp}
 */
proto.com.addrobots.grpc.FindRobotResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ResponseHeader;
      reader.readMessage(value,common_pb.ResponseHeader.deserializeBinaryFromReader);
      msg.setRespHeader(value);
      break;
    case 2:
      var value = new proto.com.addrobots.grpc.Robot;
      reader.readMessage(value,proto.com.addrobots.grpc.Robot.deserializeBinaryFromReader);
      msg.setRobot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.addrobots.grpc.FindRobotResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.addrobots.grpc.FindRobotResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.addrobots.grpc.FindRobotResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.FindRobotResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRespHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getRobot();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.addrobots.grpc.Robot.serializeBinaryToWriter
    );
  }
};


/**
 * optional com.addrobots.ResponseHeader resp_header = 1;
 * @return {?proto.com.addrobots.ResponseHeader}
 */
proto.com.addrobots.grpc.FindRobotResp.prototype.getRespHeader = function() {
  return /** @type{?proto.com.addrobots.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, common_pb.ResponseHeader, 1));
};


/**
 * @param {?proto.com.addrobots.ResponseHeader|undefined} value
 * @return {!proto.com.addrobots.grpc.FindRobotResp} returns this
*/
proto.com.addrobots.grpc.FindRobotResp.prototype.setRespHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.FindRobotResp} returns this
 */
proto.com.addrobots.grpc.FindRobotResp.prototype.clearRespHeader = function() {
  return this.setRespHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.FindRobotResp.prototype.hasRespHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Robot robot = 2;
 * @return {?proto.com.addrobots.grpc.Robot}
 */
proto.com.addrobots.grpc.FindRobotResp.prototype.getRobot = function() {
  return /** @type{?proto.com.addrobots.grpc.Robot} */ (
    jspb.Message.getWrapperField(this, proto.com.addrobots.grpc.Robot, 2));
};


/**
 * @param {?proto.com.addrobots.grpc.Robot|undefined} value
 * @return {!proto.com.addrobots.grpc.FindRobotResp} returns this
*/
proto.com.addrobots.grpc.FindRobotResp.prototype.setRobot = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.FindRobotResp} returns this
 */
proto.com.addrobots.grpc.FindRobotResp.prototype.clearRobot = function() {
  return this.setRobot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.FindRobotResp.prototype.hasRobot = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.addrobots.grpc.FindRobotListResp.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.addrobots.grpc.FindRobotListResp.prototype.toObject = function(opt_includeInstance) {
  return proto.com.addrobots.grpc.FindRobotListResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.addrobots.grpc.FindRobotListResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.FindRobotListResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    respHeader: (f = msg.getRespHeader()) && common_pb.ResponseHeader.toObject(includeInstance, f),
    robotList: jspb.Message.toObjectList(msg.getRobotList(),
    proto.com.addrobots.grpc.Robot.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.addrobots.grpc.FindRobotListResp}
 */
proto.com.addrobots.grpc.FindRobotListResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.addrobots.grpc.FindRobotListResp;
  return proto.com.addrobots.grpc.FindRobotListResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.addrobots.grpc.FindRobotListResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.addrobots.grpc.FindRobotListResp}
 */
proto.com.addrobots.grpc.FindRobotListResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ResponseHeader;
      reader.readMessage(value,common_pb.ResponseHeader.deserializeBinaryFromReader);
      msg.setRespHeader(value);
      break;
    case 2:
      var value = new proto.com.addrobots.grpc.Robot;
      reader.readMessage(value,proto.com.addrobots.grpc.Robot.deserializeBinaryFromReader);
      msg.addRobot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.addrobots.grpc.FindRobotListResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.addrobots.grpc.FindRobotListResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.addrobots.grpc.FindRobotListResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.addrobots.grpc.FindRobotListResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRespHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getRobotList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.addrobots.grpc.Robot.serializeBinaryToWriter
    );
  }
};


/**
 * optional com.addrobots.ResponseHeader resp_header = 1;
 * @return {?proto.com.addrobots.ResponseHeader}
 */
proto.com.addrobots.grpc.FindRobotListResp.prototype.getRespHeader = function() {
  return /** @type{?proto.com.addrobots.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, common_pb.ResponseHeader, 1));
};


/**
 * @param {?proto.com.addrobots.ResponseHeader|undefined} value
 * @return {!proto.com.addrobots.grpc.FindRobotListResp} returns this
*/
proto.com.addrobots.grpc.FindRobotListResp.prototype.setRespHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.addrobots.grpc.FindRobotListResp} returns this
 */
proto.com.addrobots.grpc.FindRobotListResp.prototype.clearRespHeader = function() {
  return this.setRespHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.addrobots.grpc.FindRobotListResp.prototype.hasRespHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Robot robot = 2;
 * @return {!Array<!proto.com.addrobots.grpc.Robot>}
 */
proto.com.addrobots.grpc.FindRobotListResp.prototype.getRobotList = function() {
  return /** @type{!Array<!proto.com.addrobots.grpc.Robot>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.addrobots.grpc.Robot, 2));
};


/**
 * @param {!Array<!proto.com.addrobots.grpc.Robot>} value
 * @return {!proto.com.addrobots.grpc.FindRobotListResp} returns this
*/
proto.com.addrobots.grpc.FindRobotListResp.prototype.setRobotList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.addrobots.grpc.Robot=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.addrobots.grpc.Robot}
 */
proto.com.addrobots.grpc.FindRobotListResp.prototype.addRobot = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.addrobots.grpc.Robot, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.addrobots.grpc.FindRobotListResp} returns this
 */
proto.com.addrobots.grpc.FindRobotListResp.prototype.clearRobotList = function() {
  return this.setRobotList([]);
};


goog.object.extend(exports, proto.com.addrobots.grpc);
