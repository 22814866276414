import { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import { Query, QueryResult } from '@material-table/core';
import { useSelector } from 'react-redux';
import Knob from 'react-canvas-knob';
import useTableFormTheme from '../styles/tableFormTheme';
import { DeviceRowData, findDevices } from '../logic/RobotViewLogic';
import { ApplicationState } from '../redux/system/types';
import useMainTheme from '../styles/mainTheme';

export interface RobotControllerProps extends RouteComponentProps {
	robotId?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RobotController = function RobotController(props: RobotControllerProps): JSX.Element {
	const { robotId: propsRobotId } = props;
	const [targetRobotId] = useState(propsRobotId != null ? propsRobotId : '');
	const [devices, setDevices] = useState<QueryResult<DeviceRowData>>();
	const [knobValue, setKnobValue] = useState<Map<string, number>>(new Map());
	const sessionToken = useSelector((state: ApplicationState) => state.session.sessionToken);

	const query: Query<DeviceRowData> = {
		filters: [],
		orderBy: {},
		orderDirection: 'asc',
		page: 0,
		pageSize: 0,
		totalCount: 0,
		search: '',
	};

	useEffect((): void | (() => void | undefined) => {
		const devicesPromise: Promise<QueryResult<DeviceRowData>> = findDevices(
			query,
			sessionToken,
		);
		devicesPromise.then((value) => {
			setDevices(value);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handleChange(deviceId: string, newValue: number): void {
		const newKnobValue: Map<string, number> = new Map(knobValue.set(deviceId, newValue));
		setKnobValue(newKnobValue);
	}

	return (
		<div style={{ marginTop: useMainTheme().spacing(7) }}>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={useTableFormTheme()}>
					<Paper>
						<Typography variant="h6" gutterBottom>
							Robot Controller: {targetRobotId}
						</Typography>
						{devices?.data.map((device) => {
							return (
								<div key={device.guid}>
									<Typography variant="subtitle2" gutterBottom>
										Device: {device.description}
									</Typography>
									<Knob
										// eslint-disable-next-line @typescript-eslint/ban-ts-comment
										// @ts-ignore
										value={
											knobValue.get(device.guid) === undefined
												? 0
												: knobValue.get(device.guid)
										}
										onChange={(value: number): void =>
											handleChange(device.guid, value)
										}
										width={150}
										max={360}
										step={0.01}
										cursor={20}
										stopper={false}
										font="Roboto"
									/>
								</div>
							);
						})}
					</Paper>
				</ThemeProvider>
			</StyledEngineProvider>
		</div>
	);
};

RobotController.defaultProps = {
	robotId: '',
};

export default RobotController;
